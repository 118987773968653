import styled from "styled-components"
import { RegistrationForm } from "./template";
import useAuth from "../../utils/useAuth";
import { Column } from "../../components/defaults";

import loadingIcon from '../../images/3-dots-move-purple.svg'
import { personalDetailTwo, sampleDetail } from "../../constants/registration/personal-detail-two";

let fields = Object.values({
    ...sampleDetail,
    ...personalDetailTwo
})

const requiredFields = [
    'sample_collected_today',
    'weight',
    'height_inches',
    'height_feet',
    'overall_health'
]

const requiredFieldsAuth = [
    'sample_collected_today',
    'overall_health',
    'weight'
]

const nonAuthfields = [
    "sample_collected_today",
    "weight",
    "height",
    "overall_health",
];

const authFields = [
    "sample_collected_today",
    "weight",
    "overall_health",
];

const Fields = styled.div`
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    margin-top: 30px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-template-areas: ${props => props.templateAreas}
`;

const validations = {
    registered_at: (registered_at, values) => {
        if (values.sample_collected_today.value === 'No' && !registered_at) throw new Error('This field is required')
    }
}

const LoadingWrapper = styled(Column)`
    width: 500px;
    height: 90vh;
    align-items: center;
    justify-content: center;
`;

const LoadingIcon = styled.img`
    height: 40px;
`;

const Loading = () => {

   return <LoadingWrapper>
        <LoadingIcon src={loadingIcon} />
   </LoadingWrapper>
}

export const RegistrationForm03 = () => {

    const { currentUser, isLoading } = useAuth();

    if (isLoading) return <Loading />;
    
    const thisFields = currentUser ? authFields : nonAuthfields;
    fields = fields.filter((f) => thisFields.includes(f.id));
    const _requiredFields = currentUser ? requiredFieldsAuth : requiredFields;
    const _templateAreas = thisFields.map((v) => `"${v}"`).join('\n')

    return <RegistrationForm
        subtitle='Personal Details'
        title='Please tell us a little more about yourself.'
        Fields={Fields}
        fields={fields}
        progress={48}
        requiredFields={_requiredFields}
        templateAreas={_templateAreas}
        validations={validations}
        nextRoute='/registration/4'
    />
    
}