import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {
    first_name: '',
    last_name: '',
    gender: '',
    biological_sex: '',
    date_of_birth: '',
    country: '',
    phone: '',
    ethnicity: '',
  },
  error: {},
  unsaved: false
}

export const userDataSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        clear: () => {
            return initialState
        },
        setUserDataError: (state, action) => {
            state.error = action.payload
        },
        setUserDataValues: (state, action) => {
            state.data[action.payload.id] = action.payload.value
            state.unsaved = false
        },
        updateUserDataValue: (state, action) => {
            state.data[action.payload.id] = action.payload.value
            state.unsaved = true
        },
        toggleUserDataUnsaved: (state, action) => {
            state.unsaved = action.payload;
        }
    },
})

export const { 
    clear, 
    updateUserDataValue, 
    setUserDataValues, 
    setUserDataError, 
    toggleUserDataUnsaved 
} = userDataSlice.actions;

export default userDataSlice.reducer;
  