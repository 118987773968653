export const mentalHealth = {
    mental_health: {
        label: 'How would you rate your mental health?',
        id: 'mental_health',
        options: [
            { label: 'Excellent', value: 'Excellent' },
            { label: 'Good', value: 'Good' },
            { label: 'Fair', value: 'Fair'},
            { label: 'Poor', value: 'Poor'}
        ],
        noDropdown: true,
        optionsGridStyle: {
            gridAutoFlow: 'column',
            gridTemplateRows: '1fr 1fr',
            gridTemplateColumns: 'repeat(2, 1fr)'
        }
    },
    social_life: {
        label: 'Are you satisfied with your social life?',
        id: 'social_life',
        options: [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
            { label: 'Rather Not Say', value: 'Rather Not Say'}
        ],
        noDropdown: true,
        optionsGridStyle: {
            gridAutoFlow: 'column',
            gridTemplateRows: '1fr',
            gridTemplateColumns: 'repeat(2, min-content) 1fr'
        }
    },
    stress: {
        label: `How stressed are you?`,
        id: 'stress',
        options: [
            { label: 'Not at all', value: 'Not at all'},
            { label: 'Somewhat stressed', value: 'Somewhat stressed' },
            { label: 'Really stressed', value: 'Really stressed' },
            { label: 'Extremely stressed', value: 'Extremely stressed' },
        ]
    },

}