import { useDispatch, useSelector } from "react-redux";
import { MainInput } from "./input";
import { updateUserDataValue } from "../redux/reducer/user_data";

export const UserUpdateInput = (props) => {

    const {
        id,
        disableTrim,
        numberOnly,
        subIds,
        nestedQuestion
    } = props
    
    const dispatch = useDispatch();

    const valueReducer = (state) => (prev, key) => {
        return {...prev, [key]: state.userData.data[key]}
    }

    const value = useSelector((state) => {   
        return subIds ? subIds?.reduce(valueReducer(state), {}) :
        nestedQuestion?.id ? [nestedQuestion?.id, id].reduce(valueReducer(state), {}) :
        state.userData.data[id]
    });

    const onChangePhone = (overrideId) => (e) => {
        const thisId = (overrideId) ? overrideId : id;
        dispatch(updateUserDataValue({
            id: thisId, value: typeof e === 'string' ?
            e : e?.target?.value
        })
    )}

    const onChangePassword = (overrideId) => (e) => {
        const thisId = (overrideId) ? overrideId : id;
        dispatch(updateUserDataValue({
            id: thisId, value: typeof e === 'string' ?
            e : e?.target?.value
        })
    )}

    const onChangeDefault = (overrideId) => (e) => {

        const thisId = (overrideId) ? overrideId : id;

        let nextValue = (typeof e === 'string' 
            || Array.isArray(e)
            || !!e.value) ?
        e : e?.target?.value;

        if (!disableTrim && typeof nextValue === 'string') {
            nextValue = nextValue.trim()
        }

        if (numberOnly && typeof nextValue === 'string') {
            nextValue = nextValue.replace(/[^0-9.]/g, '')
        }

        dispatch(updateUserDataValue({ id: thisId, value: nextValue }))

    }
    
    const errors = useSelector((state) => state.userData.error);
    

    const onChange = props.type === 'phone' ?
    onChangePhone : props.type === 'password' ?
    onChangePassword : onChangeDefault;


    return <MainInput
    value={value}
    onChange={onChange}
    errors={errors}
    {...props}
    />

}

