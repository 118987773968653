import React from 'react';
import styled from 'styled-components';
import ageBgEllipse from '../images/gradient-flare.svg';
import { Column, Row } from './defaults';
import clockIcon from '../images/white-clock.svg';
import calendar from '../images/calendar-white.svg';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { FinalAge } from './final-age';
import { useEffect } from 'react';

const AgeBgEllipse = styled.img`
    width: 500px;
`;

const BgWrapper = styled(Column)`
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 0;
    align-items: center;
`;


const ResultDateWrapper = styled(Row)`
    color: white;
    font-size: 14px;
    gap: 14px;
    align-items: center;
    img {
        height: 20px;
    }
    z-index: 1;

`;


const ChronologicalAgeWrapper = styled(Row)`
    border-radius: 100px;
    padding: 10px 20px;
    font-size: 18px;
    gap: 8px;
    color: white;
    background: rgba(255,255,255,0.08);
    z-index: 1;

    img {
        height: 24px;
    }
`;  

const Wrapper = styled(Column)`
    padding-bottom: 100px;
    align-items: center;
    justify-content: center;
    position: relative;
    
    @media (max-width: 1100px) {
        width: 100vw;
    }
`;

const AgeInfoFulfilledWrapper = styled(Column)`
    position: relative;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 80px;
    justify-self: center;

    @media (max-width: 1100px) {
        width: 100vw;
    }
`;

export const AgeInfoFulfilled = () => {

    const ageData = useSelector((state) => ({ results: state.user.userData.health_results, kits: state.user.userData.kits }));
    const lastAgeData = ageData.results[0];
    const kitData = ageData.kits.find((k) => k.id === lastAgeData.kit_id)

    return <AgeInfo 
    ReplaceWrapper={AgeInfoFulfilledWrapper}
    {...{
        ageData,
        lastAgeData,
        kitData
    }}/>

}


const ResultsDate = ({ children }) => {

    return <ResultDateWrapper>
        <img src={calendar} alt=''/>
        {children}
    </ResultDateWrapper>

}


export const AgeInfo = ({
    lastAgeData,
    kitData,
    ReplaceWrapper,
    wrapperStyle,
}) => {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const ThisWrapper = ReplaceWrapper || Wrapper
            
    return <ThisWrapper style={wrapperStyle}>
        <ChronologicalAgeWrapper>
            <img src={clockIcon} alt=''/>
            Chronological Age: {lastAgeData.chronological_age}
        </ChronologicalAgeWrapper>
        <FinalAge age={lastAgeData.biological_age} />
        {kitData?.registered_at && <ResultsDate>{moment(kitData.registered_at).format('MMMM D, YYYY')} Results</ResultsDate>}
        <BgWrapper>
            <AgeBgEllipse src={ageBgEllipse}/>
        </BgWrapper>
    </ThisWrapper>  

    
}

