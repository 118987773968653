import styled from "styled-components"
import { RegistrationForm } from "./template";
import { personalDetail } from "../../constants/registration/personal-detail";

const Fields = styled.div`
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    margin-top: 30px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-template-areas: 
        "date_of_birth"
        "biological_sex"
        "gender"
        "country"
        "ethnicity"
`;

const requiredFields = [
    'date_of_birth',
    'biological_sex',
    'gender',
    'country',
    'ethnicity',
]

export const RegistrationForm02 = () => {

    return <RegistrationForm
        subtitle='Personal Details'
        title='Please provide the following information:'
        Fields={Fields}
        fields={Object.values(personalDetail)}
        progress={32}
        requiredFields={requiredFields}
        nextRoute='/registration/3'

    />
    
}