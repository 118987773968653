import React, { useEffect, useState } from "react";
import styled from 'styled-components'
import { Column } from "../components/defaults";
import { useNavigate, useParams } from "react-router-dom";
import { decryptToken } from "../utils/age";
import logo from '../images/logo-dark.svg'
import { Row } from '../components/defaults';
import arrow from '../images/arrow-navy.svg';
import { AgeInfo } from '../components/age-message';
import { convertYearsToYearsAndMonths } from "../utils/date";
import Helmet from 'react-helmet';
import { capitalize } from "../utils/formatters";


const ResultText = styled.div`
    color: white;
    font-family: 'Satoshi-Regular';
    line-height: 40px;
    font-size: 35px;

    @media (max-width: 1100px) {
        font-size: 30px;
        width: 80%;
        align-self: center;
    }
    
    b {
        color: rgba(30, 220, 255, 1)
    }
`;


const ContentsButton = styled(Row)`
    cursor: pointer;
    white-space: nowrap;
    background: ${props => props.background ? props.background : 'transparent'};
    border: 1px solid white;
    border-radius: 100px;
    padding: 13px 24px;
    color: ${ props => props.color ? props.color : 'white'};
    cursor: pointer;
    align-items: center;
    grid-area: button;

    img {
        margin-left: 10px;
        height: 15px;
    }


    @media (max-width: 1100px) {
        z-index: 10000;
        justify-self: center;
        max-width: 400px;
        text-align:center;
    }

    @media (min-width: 1100px) {
        align-self: flex-start;
        justify-self: flex-end;
        max-width: 400px;
    }

`; 


const Logo = styled.img`
    height: 70px;
    cursor: pointer;
`;

const MainWrapper = styled(Column)`
    
    @media (min-width: 1500px) {
        background: #0A1330;
        width: 100vw;
        align-items: center;
    }

`;

const ResultHeadlineWrapper = styled(Column)`
    grid-area: headline;
    

    @media (max-width: 1100px) {
        margin-top: -80px;
        z-index: 10000;
        justify-self: center;
        max-width: 400px;
        text-align:center;
    }

    @media (min-width: 1100px) {
        align-self: flex-end;
        justify-self: flex-end;
        text-align: right;
        max-width: 400px;
    }
`;

const Meta = () => {

    return <Helmet>
    </Helmet>

}


export default function Share() {

    const { token } = useParams();
    const [ data, setData ] = useState({});
    
    useEffect(() => {
        const getResult = () => {
            const result = decryptToken(token);
            setData(result)
        }
        if (!!token) getResult(token)
    },[token])

    const diff = Math.abs(data.biological_age - data.chronological_age);
    const [years, months] = convertYearsToYearsAndMonths(diff);

    return <MainWrapper>
        <Contents 
        years={years}
        months={months}
        lower={data.biological_age < data.chronological_age}
        chronological_age={data?.chronological_age}
        first_name={data?.first_name}
        possessive_pronoun={data.possessive_pronoun}
        biological_age={data?.biological_age}/>
    </MainWrapper>


}

const ResultHeadline = ({
    years,
    months,
    lower,
    first_name,
    possessive_pronoun
}) => {

    const yearString = years === 1 ? 'year' : 'years';
    const monthString = months === 1 ? 'month' : 'months';
    const lowerString = lower ? 'lower' : 'higher'

    return <ResultHeadlineWrapper>
        <ResultText>{capitalize(first_name || '')}'s TruMe age is <b>{years} {yearString}</b>
        {months ? <span> and <b>{months} {monthString}</b></span> : null}
        <span> {lowerString} than {possessive_pronoun} chronological age.</span></ResultText>
    </ResultHeadlineWrapper>

}

const LogoWrapper = () => {
    return <Logo onClick={() => window.open('https://trumelabs.com', '__blank')} src={logo} />
}
const Wrapper = styled.div`
    background: #0A1330;
    display: grid;
    gap: 20px;

    @media (min-width: 1500px) {
        width: 1500px;
    }

    @media (max-width: 1100px) {
        grid-template-columns: 1fr;
        grid-template-rows: min-content min-content min-content;
        grid-template-areas:
            'age'
            'headline'
            'button';
        padding-top: 100px;
        padding-bottom: 180px;

    }

    @media (min-width: 1100px) {
        grid-template-columns: 550px 1fr;
        grid-template-rows: min-content min-content ;
        grid-template-areas:
            'headline age'
            'button age';
        padding-bottom: 80px;
        padding-top: 70px;
    }
   
`;  

const Header = styled(Column)`
    height: 130px;
    background: #0A1330;
    justify-content: center;

    @media (max-width: 1100px) {
        justify-content: center;
        align-items: center;
    }

    @media (min-width: 1100px) {
        justify-content: center;
        align-items: flex-start;
        padding-left: 100px;
    }

`;


const Contents = (props) => {

    return <>
    <Meta />
    <Header>
        <LogoWrapper />
    </Header>
    <Wrapper>
        <ResultHeadline {...props} />
        <ContentsButton onClick={() => window.open('https://trumelabs.com/biological-age-test/','_blank')} background='white' color='rgba(5, 31, 115, 1)'>
            Discover your TruMe Age <img src={arrow} alt='' />
        </ContentsButton>
        <AgeInfo wrapperStyle={{ gridArea: 'age' }} lastAgeData={props} />
    </Wrapper>
    </>
}











