import styled from "styled-components"
import { RegistrationForm } from "./template";
import { medicalHistory } from "../../constants/registration/medical-history";

const requiredFields = [
    'supplements',
    'medicine',
    'has_diabetes',
    'has_cancer',
]

const validations = {
    'supplement_consumption': (supplementConsumption, values) => {
        if (values?.supplements?.value === 'Yes' && !supplementConsumption) throw new Error('this field is required')
    },
    'medicine_consumption': (medicineConsumption, values) => {
        if (values?.medicine?.value === 'Yes' && !medicineConsumption) throw new Error('this field is required')
    }
}

const Fields = styled.div`
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    margin-top: 30px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-template-areas: 
        "has_cancer"
        "has_diabetes"
        "medicine"
        "supplements"
`;

export const RegistrationForm05 = () => {

    return <RegistrationForm
        subtitle='Medical History'
        title='Details about your medical condition.'
        Fields={Fields}
        fields={Object.values(medicalHistory)}
        progress={70}
        nextRoute='/registration/6'
        requiredFields={requiredFields}
        validations={validations}
    />
    
    
}