import styled from "styled-components"
import { Button, Column, Row } from "../components/defaults";
import { accountInformation } from "../constants/registration/account-info";
import { UserUpdateInput } from "../components/user-update";
import { personalDetail } from "../constants/registration/personal-detail";
import { useEffect, useState } from "react";
import api from "../utils/api";
import { clear, setUserDataError, setUserDataValues, toggleUserDataUnsaved } from "../redux/reducer/user_data";
import { useDispatch, useSelector } from "react-redux";
import { dateInputFormatter, dropdownInputFormatter } from "../utils/formatters";
import loadingIcon from '../images/3-dots-move-purple.svg';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from "react-router-dom";

const ProfileSectionWrapper = styled(Row)`
    padding: 40px;

    @media (max-width: 800px) {
        flex-direction: column;
        padding: 30px;
        gap: 30px;
        margin-bottom: 70px;
    }
`;


const LoadingIcon = styled.img`
  height: 45px;
  width: 45px;
  margin-left: 40px;
  margin-right: 40px;
`;

const SectionTitle = styled.div`
    font-weight: 500;
    font-size: 32px;
    line-height: 43px;
    color: #051F73;
    flex: 1;
`;

const Form = styled(Column)`
    gap: 24px;
    flex: 1;
`;

const BottomBar = styled(Row)`
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 85px;
    align-items: center;
    justify-content: center;
    background: white;
    box-shadow: 1px 1px 5px rgba(0,0,0,.3);
    z-index: 100000000;
`;

const LoadingWrapper = styled(Column)`
    height: 80vh;
    align-items: center;
    justify-content: center;
`;


const ProfileSection = ({ title, fields }) => {
    
    return <ProfileSectionWrapper>
        <SectionTitle>
            {title}
        </SectionTitle>
        <Form>
            {fields.map((field) => (<UserUpdateInput {...field} />) )}
        </Form>
    </ProfileSectionWrapper>

}


const mapper = (data, fullSchema, dispatch) => {

    for (let key of Object.keys(data)){
        const schema = fullSchema[key];
        let value = data[key];
        if (!schema) continue;
        if (schema.type === 'date') value = dateInputFormatter(value);
        if (schema.options) value = dropdownInputFormatter(value, schema.options) || {};
        dispatch(setUserDataValues({ id: key, value }))
    };

}

const remapper = (data, fullSchema) => {

    for (let key of Object.keys(data)){
        const schema = fullSchema[key];
        let value = data[key];
        if (!schema) continue;
        if (Array.isArray(value)) value = value[0];
        if (schema.options) value = value.value;
        data[key] = value
    };

}

export const Profile = () => {

    useEffect(() => {
        fetchUserData();
    }, [])

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const shouldUpdate = useSelector((state) => state.userData.unsaved);
    const allData = {...useSelector((state) => state.userData.data)}

    const fullSchema = {
        ...accountInformation,
        ...personalDetail,
    }

    delete fullSchema['email']

    const fetchUserData = async () => {
        dispatch(clear())
        const result = await api.get('/user-data')
        const data = result.data;
        mapper(data, fullSchema, dispatch)
        setLoading(false);
    }

    const validate = () => {
        dispatch(setUserDataError({}));
        const errors = {}
        for (let key of Object.keys(allData)) {
            if (!allData[key]) errors[key] =  'This field is Required';
        }
        dispatch(setUserDataError(errors));
        return Object.keys(errors).length === 0;
    }



    const submit = async () => {
        if (!validate()) return
        setLoadingSubmit(true)
        remapper(allData, fullSchema);
        await api.post('/user-data', allData);
        dispatch(toggleUserDataUnsaved(false));
        setLoadingSubmit(false)
        NotificationManager.success('Your data was successfully updated');
    }

    let accInformation = Object.assign({}, accountInformation)
    delete accInformation['repeat_password'];
    delete accInformation['password'];
    delete accInformation['email'];
    accInformation = Object.values(accInformation);


    if (loading) return <LoadingWrapper>
        <LoadingIcon src={loadingIcon} />
    </LoadingWrapper>

    return <div>
        <ProfileSection 
        fields={accInformation}
        title='Account Information' />

        <ProfileSection 
        fields={Object.values(personalDetail)}
        title='Personal Detail' />

        {shouldUpdate && <BottomBar>
            <Button 
            onClick={submit}
            loading={loadingSubmit}
            style={{ fontSize: 15, whiteSpace: 'nowrap'}}>Confirm changes</Button>    
        </BottomBar>}
    </div>

}