export const accountInformation = {
    first_name: {
        label: 'First Name',
        id: 'first_name',
        placeholder: 'John',
    },
    last_name: {
        label: 'Last Name',
        id: 'last_name',
        placeholder: 'Doe',
    },
    phone: {
        label: 'Phone Number',
        id: 'phone',
        type: 'phone',
        placeholder: '000-000-000',
    },
    email: {
        label: 'Email',
        id: 'email',
        placeholder: 'johndoe@mail.com',
    },
    password: { 
        label: 'Your Password',
        type: 'password',
        id: 'password',
        placeholder: '•••••••••••••••••'
    },
    repeat_password: { 
        label: 'Repeat your Password',
        type: 'password',
        id: 'repeat_password',
        placeholder: '•••••••••••••••••'
    }
}