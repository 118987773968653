/* eslint-disable react-hooks/exhaustive-deps */
import styled from "styled-components"
import { RegistrationForm } from "./template";
import { isInvalidEmail, isInvalidPw } from "../../utils/validator";
import api from "../../utils/api";
import { accountInformation } from "../../constants/registration/account-info";


const requiredFields = [
    'first_name',
    'last_name',
    'email',
    'password',
    'repeat_password',
    'phone'
]

const verifyEmail = async (email) => {
    try {
        await api.get(`/email?value=${email}`);
    } catch (err) {
        throw new Error('Email already registered')
    }
}

const validations = {
    email: async (value) => {
        isInvalidEmail(value)
        await verifyEmail(value)
    },
    password: (value) => {
        isInvalidPw(value)
    },
    other: (values) => {
        if (values.password !== values.repeat_password) throw new Error('Passwords must match')
    }
}

const Fields = styled.div`
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    margin-top: 30px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-template-areas: 
        "first_name last_name"
        "email email"
        "phone phone"
        "password password"
        "repeat_password repeat_password";
    box-sizing: border-box;
`;

export const RegistrationForm01 = () => {

    return <RegistrationForm
        subtitle='Account Information'
        title='Can you confirm the following details?'
        Fields={Fields}
        fields={Object.values(accountInformation)}
        progress={16}
        nextRoute='/registration/2'
        requiredFields={requiredFields}
        validations={validations}
    />
    
}

