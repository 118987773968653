import moment from "moment"


export const dateInputFormatter = (dateString) => {

    return moment(new Date(dateString)).format('YYYY-MM-DD')

}

export const dropdownInputFormatter = (value, options ) => {
    return options.find((op) => op?.value === value)
}

export const capitalize = (value) => {
    if (!value) {
        return value;
    }
    return `${value[0].toLocaleUpperCase()}${value.substring(1).toLocaleLowerCase()}`
}
