import React, { useRef, useEffect, useState } from 'react';
import moment from 'moment';
import { 
    select, 
    scaleTime, 
    scaleLinear, 
    axisBottom, 
    axisLeft, 
    line, 
    curveMonotoneX,
    min,
    max,
    area,
    selectAll,
    curveLinear,
    curveBasis
} from 'd3';
import { Row } from './defaults';
import { useSelector } from 'react-redux';

const mockData = [
    { date: new Date('2023-01-01'), value: 0 },
    { date: new Date('2023-02-01'), value: 0 },
    { date: new Date('2023-03-01'), value: 0 },
    { date: new Date('2023-04-01'), value: 0 },
    { date: new Date('2023-05-01'), value: 0 },
    { date: new Date('2023-06-01'), value: 0 },
    { date: new Date('2023-07-01'), value: 0 },
];


const LineGraph = ({ 
    data = mockData, 
    lowerColor = '#1EDCFF',
    textColor = 'white',
    focusPoint ,
    forceDomain,
}) => {

    let refData = data
    const graphContainer = useRef(null);
    const axisContainer = useRef(null);
    const [windowSize, setWindowSize] = useState(0)

    useEffect(() => {
        const listener = window.addEventListener('resize', (e) => setWindowSize((v) => v + 1))
        return () => window.removeEventListener('resize', listener);

    }, [])


    useEffect(() => {
        if (data && graphContainer.current && axisContainer.current) {
            const xSpacing = 100;
            const sidePadding = 60
            const numberOfMonths = refData.length
            let width = Math.max((numberOfMonths * xSpacing) + (sidePadding * 2));
            
            let height = 360;
            let contentHeight = height - 70;
            let graph = select(graphContainer.current);
            let fixedAxis = select(axisContainer.current);
            let expectedGraphWidth = graphContainer.current.getBoundingClientRect().width;

            fixedAxis.selectAll('*').remove();
            graph.selectAll("*").remove();

            let axisWrapper = fixedAxis.append('svg')
            .attr('width', 40)
            .attr('height', height)
            .append('g')
            .append('g')
            .attr('width', '100%')
            .attr('height', height)
            .attr('transform', `translate(10, 0)`)
            
            graph = graph.append('svg')
            .attr('width', Math.max(expectedGraphWidth, width))
            .attr('height', height)

            const all_values = refData.reduce((prev, d) => [...prev, Math.abs(d.chronological_age), Math.abs(d.biological_age)], []);


            let lower = all_values.length === 1 ? all_values[0] - 6 : Math.min(...refData.map((d) => Math.abs(d.biological_age)));
            let upper = all_values.length === 1 ? all_values[0] + 6 : Math.max(...refData.map((d) => Math.abs(d.biological_age)));
            
            if (lower === upper) {
                lower = Math.max(0, lower - 30);
                upper = upper + 30;
            }

            
            //console.log(refData)
            let domain = (forceDomain || [lower - 10, upper + 10])

            let svg = graph.append('g')
            .attr('width', '100%')
            .attr('height', height)
            .attr('transform', `translate(0, 0)`)

            const x = scaleTime()
            .domain([min(refData, d => d.date), moment().add(1, 'day').toDate()])
            .range([sidePadding, width - sidePadding]);

            const y = scaleLinear()
            .domain(domain)
            .range([contentHeight, 0]);

            if (focusPoint) {
                const width = 20
                svg.append('rect')
                .attr('x', x(new Date(focusPoint)) - width/2)
                .attr('width', width)
                .attr('y',y(upper) + 5)
                .attr('height', y(lower) - 10)
                .attr('rx', 8)
                .attr('ry', 8)
                .attr('fill', 'rgba(79, 89, 240, 1)')
            }


            const distanceInMonths = moment().diff(moment(refData[0].date), 'month')
            const months = distanceInMonths > 10 ? 5 :
            distanceInMonths > 5 ? 3 : 2
            const xTicks = [];
            const spacing = moment().diff(moment(refData[0].date), 'milliseconds') / months;
            let startTime = moment(refData[0].date)
            for (let i = 0; i <= months; i ++) {
                xTicks.push(startTime.toDate());
                startTime = startTime.add(spacing, 'milliseconds')
            }
            
            const xAxis = axisBottom(x)
            .tickSize(0)
            .tickPadding(50)
            .tickValues(xTicks)
            .tickFormat((v, index) => {
                return `${moment(v).format('MMM')} ‘${moment(v).format('YY')}`
            })
            

            svg.append('g')
            .attr('transform', `translate(0,${contentHeight})`)
            .call(xAxis)
            .call(g => g.select(".domain").remove())
            .selectAll('text')
            .style('font-size', '14px')
            .style('font-family', 'Satoshi-Medium')
            .style('fill', textColor);
        

            let ticks = []
            
            for (let tick = lower; 
                tick < Math.abs(upper) + 0.3;
                tick += (Math.abs(upper) * 2) / 4) {
                ticks.push(tick.toFixed(0))
            }
            



            svg.append('path')
            .datum(data)
            .attr('fill', 'none')
            .attr('stroke', lowerColor)
            .attr('strokeWidth', 3)
            .attr('d', line()
                .x(d => x(d.date))
                .y(d => y(d.biological_age))
                .curve(curveMonotoneX)
            )


            svg.append('path')
            .datum(data)
            .attr('fill', 'none')
            .attr('stroke', 'gray')
            .attr('strokeWidth', 10)
            .attr('d', line()
                .x(d => x(d.date))
                .y(d => y(d.chronological_age))
                .curve(curveMonotoneX)
            )

            var tooltip = selectAll("#graph-tooltip");

            svg.selectAll(".line-diff")
            .data(data)
            .enter().append("line")  // Append a 'line' element for each data point
            .attr('class', 'diff')  // Class for styling
            .attr('x1', d => x(d.date))  // X position for the start of the line
            .attr('x2', d => x(d.date))  // X position for the end of the line (same as x1 for a vertical line)
            .attr('y1', d => y(d.chronological_age))  // Y position for the start of the line
            .attr('y2', d => y(d.biological_age))  // Y position for the end of the line
            .attr('stroke', 'rgba(0,0,0,.3)')
            .attr('stroke-width', 1)
            .style("z-index", 1)
            .attr('stroke-dasharray', '2,2')
            ;
            
            svg.selectAll(".dot")
            svg.selectAll(".dot")
            .data(data)
            .enter().append("circle")
            .attr("class", "dot")
            .attr("cx", d => x(d.date))
            .attr("cy", d => y(d.biological_age))
            .attr("r", 6)
            .attr("fill", "#ffffff")
            .attr("stroke", "#F9BF29")
            .attr("strokeWidth", 2.5)
            .style("z-index", 10)




            svg.selectAll(".dot2")
            .data(data)
            .enter().append("circle")
            .attr("class", "dot")
            .attr("cx", d => x(d.date))
            .attr("cy", d => y(d.biological_age) - 0)
            .attr("r", 10)
            .attr("fill", "transparent")
            .attr("stroke", "transparent")
            .attr("strokeWidth", 2.5)
            .style("z-index", 10000)
            .on("mouseover", function(event, d) {
                const scrollTop = document.documentElement.scrollTop;
                const {x, y} = event.target.getBoundingClientRect();
                tooltip
                .style("left", (x - 47) + "px")
                .style("top", (y + scrollTop - 140) + "px")
                .style("transform", "translateY(30px)")
                .style("display", "initial")
                .style("pointer-events", "none")

               
                const [years, months] = d.value.toFixed(1).split('.')

                tooltip.select('#date').text(moment(d.date).format(`MMM 'YY`))
                tooltip.select('#value').text(d.biological_age?.toFixed(1));
                tooltip.select('#diff').text(`${d.value > 0 ? '+' : ''}${years}y ${months}m`);

            })
            .on("mouseout", () => {
                tooltip.style("display", "none");
            });


            svg.append('g')
            .attr('class', 'grid')
            .call(axisLeft(y)
            .ticks(5)
            .tickSize(0)
            .tickPadding(-30)
            .tickFormat((v, index) => ``)
            .tickSize(-Math.max(expectedGraphWidth, width)))
            .call(g => g.select('.domain').remove()) 
            .call(g => g.selectAll('.tick line') 
                .attr('stroke', 'rgba(0,0,0,.1)')
                .attr('stroke-dasharray', '2,2'));
            

                
            const yAxis = axisLeft(y)
            .ticks(5)
            .tickSize(0)
            .tickPadding(-30)
            .tickFormat(() => '')
            .tickFormat((v, index) => `${v} Yo`)
            
            
            axisWrapper.append('g')
            .call(yAxis)
            .call(g => g.select('.domain').remove())
            .call(g => g.selectAll('.tick line').remove())
            .selectAll('text')
            .style('font-size', '13px')
            .style('font-family', 'Satoshi-Medium')
            .style('fill', textColor)
    

            
            
            if (expectedGraphWidth < width) {
                if (focusPoint) {
                    graphContainer?.current?.scrollTo(x(focusPoint) - expectedGraphWidth + 50, 0)
                } else {
                    graphContainer?.current?.scrollTo(x(data[data.length - 1].date) - expectedGraphWidth + 50, 0)
                }
            } 
        }
    }, [data , windowSize]); // Depend on data prop

    return (<Row
    style={{ 
        display: 'flex', 
        width: '100%', 
        position: 'relative', 
        paddingRight: 50, 
    }}>
        <Row style={{ flex: 1, overflowX: 'hidden',}}>
            <div style={{
                height: 380,
                flex: 1,
                overflowX: 'scroll'
            }} className="d3-component" ref={graphContainer} />
            <div style={{ width: 50 }} />
        </Row>
        <div style={{
            height: 360,
            width: 40,
            right: 60,
            top: 0,
            position: 'absolute',
        }} className="d3-component" ref={axisContainer} />
    </Row>
    );
};

export const AgeGraph = ({ ...props }) => {
    
    const data = useSelector((s) => s.user.userData.health_results);

    const formattedData = [...data].reverse().map((d) => ({
        chronological_age: d.chronological_age,
        value: (d.biological_age - d.chronological_age),
        biological_age: d.biological_age,
        date: new Date(d.registered_at)
    }))

    const middleValue = data[data.length - 1].chronological_age;

    return <LineGraph data={formattedData} middleValue={`${middleValue.toFixed(0)}yo`} {...props} />

}


export default LineGraph;