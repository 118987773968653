import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import { persistReducer, persistStore } from 'redux-persist';
import userReducer from './reducer/user';
import registrationReducer from './reducer/registration';
import userDataReducer from './reducer/user_data';

// Configuration for local storage
const persistConfig = {
  key: 'main',
  storage: storage,
  whitelist: ['user']
};

// Configuration for session storage
const sessionPersistConfig = {
  key: 'registration',
  storage: sessionStorage,
};

const rootReducer = combineReducers({
  user: persistReducer(persistConfig, userReducer),
  registration: persistReducer(sessionPersistConfig, registrationReducer),
  userData: userDataReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export { store };