import { COUNTRIES } from "../../files/countries";

const ETHNICITY_OPTIONS = ['White', 'Hispanic and Latino', 'Black / African American', 'Asian', 'American Indian or Alaska Native', 'Native Hawaiian or Other Pacific Islander', 'Other']

export let personalDetail = {
    date_of_birth: {
        label: `What's your date of birth?`,
        id: 'date_of_birth',
        type: 'date'
    },
    biological_sex:{
        label: `What's your biological sex?`,
        id: 'biological_sex',
        options: [
            { label: 'Female', value: 'Female' },
            { label: 'Male', value: 'Male' },
            { label: 'Other', value: 'Other' }
        ]
    },
    gender: {
        label: `What gender do you identify with?`,
        id: 'gender',
        options: [
            { label: 'Female', value: 'Female' },
            { label: 'Male', value: 'Male' },
            { label: 'Other', value: 'Other' }
        ]
    },
    country: {
        label: 'What country are you located in?',
        id: 'country',
        options: COUNTRIES.map(({ label }) => ({ value: label, label }))
    },
    ethnicity: {
        label: 'What is your ethnicity?',
        id: 'ethnicity',
        options: ETHNICITY_OPTIONS.map((value) => ({ value, label: value }))
    }
}