
const yesNoOptions = {
    options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
        
    ],
    noDropdown: true,
    optionsGridStyle: {
        gridAutoFlow: 'column',
        gridTemplateRows: '1fr',
        gridTemplateColumns: 'repeat(2, min-content)'
    }
}

export let habitsLifestyle = {
    coffee_consumption: {
        label: 'Do you drink coffee?',
        id: 'coffee_consumption',
        ...yesNoOptions
    },
    tobacco_consumption: {
        label: 'Do you smoke?',
        id: 'tobacco_consumption',
        ...yesNoOptions
    },
    waking_up_condition: {
        label: 'Do you wake up rested?',
        id: 'waking_up_condition',
        ...yesNoOptions
    },
    trouble_sleeping: {
        label: 'Do you have trouble sleeping?',
        id: 'trouble_sleeping',
        ...yesNoOptions
    },
    alcohol_consumption: {
        label: 'How many alcoholic drinks do you have per week?',
        id: 'alcohol_consumption',
        options: [
            { label: '0', value: '0' },
            { label: '1-2', value: '1-2' },
            { label: '3-5', value: '3-5'},
            { label: '6-9', value: '6-9'},
            { label: '10+', value: '10+'}
        ],
        noDropdown: true,
        optionsGridStyle: {
            gridAutoFlow: 'column',
            gridTemplateRows: '1fr',
            gridTemplateColumns: '1fr'
        }
    },
    sleep_hours: {
        label: 'On average, how many hours do you sleep per night?',
        id: 'sleep_hours',
        options: [
            { label: '5 or less', value: '5' },
            { label: '6', value: '6' },
            { label: '7', value: '7'},
            { label: '8', value: '8'},
            { label: '9', value: '9'},
            { label: '10 or more', value: '10+'},
        ],
        noDropdown: true,
        optionsGridStyle: {
            gridAutoFlow: 'column',
            gridTemplateRows: '1fr 1fr',
            gridTemplateColumns: 'repeat(3, 1fr)'
        }
    },
    physical_activity_days: {
        label: 'On average, how many days per week do you engage in physical activity?',
        id: 'physical_activity_days',
        options: [
            { label: '0', value: '0' },
            { label: '1', value: '1' },
            { label: '2', value: '2'},
            { label: '3', value: '3'},
            { label: '4', value: '4'},
            { label: '5', value: '5'},
            { label: '6+', value: '6+'},
        ],
        noDropdown: true,
        noPadding: true,
        optionsGridStyle: {
            gridAutoFlow: 'column',
            gridTemplateRows: '1fr',
            gridTemplateColumns: 'repeat(7, 1fr)'
        }
    },
}