export let sampleDetail = {
    sample_collected_today: {
    label: 'Was your sample collected today?',
    options: [
        { label: 'Yes', value: 'Yes'},
        { label: 'No', value: 'No'}
    ],
    noDropdown: true,
    id: 'sample_collected_today',
    optionsGridStyle: {
        gridAutoFlow: 'column',
        gridTemplateRows: '1fr',
        gridAutoColumns: 'min-content'
    },
    nestedQuestion: {
        condition: 'No',
        label: 'When was your sample taken?',
        id: 'registered_at',
        type: 'date'
    }
}
}

export let personalDetailTwo = {
    height: {
        label: 'What is your height?',
        subIds: ['height_feet', 'height_inches'],
        id: 'height',
        placeholder: ['Height', 'Height'],
        suffix: ['ft.', 'in.'],
        numberOnly: true
    },
    weight: {
        label: 'What is your weight?',
        id: 'weight',
        placeholder: 'Your weight',
        suffix: 'lb.',
        numberOnly: true
    },
    overall_height: {
        label: 'How would you rate your overall health?',
        id: 'overall_health',
        options: [
            { label: 'Excellent', value: 'Excellent' },
            { label: 'Good', value: 'Good' },
            { label: 'Fair', value: 'Fair'},
            { label: 'Poor', value: 'Poor'}
        ],
        noDropdown: true,
        optionsGridStyle: {
            gridAutoFlow: 'column',
            gridTemplateRows: '1fr 1fr',
            gridTemplateColumns: 'repeat(2, 1fr)'
        }
    }

}

