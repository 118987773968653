export const CHAR_MAPPING = {
    "A": "4",
    "B": "M",
    "C": "T",
    "D": "E",
    "E": "U",
    "F": "V",
    "G": "b",
    "H": "R",
    "I": "0",
    "J": "D",
    "K": "l",
    "L": "8",
    "M": "F",
    "N": "w",
    "O": "G",
    "P": "i",
    "Q": "N",
    "R": "X",
    "S": "j",
    "T": "v",
    "U": "H",
    "V": "n",
    "W": "f",
    "X": "Y",
    "Y": "m",
    "Z": "C",
    "a": "J",
    "b": "k",
    "c": "S",
    "d": "7",
    "e": "c",
    "f": "W",
    "g": "Z",
    "h": "h",
    "i": "q",
    "j": "K",
    "k": "r",
    "l": "O",
    "m": "3",
    "n": "u",
    "o": "9",
    "p": "o",
    "q": "L",
    "r": "B",
    "s": "y",
    "t": "P",
    "u": "I",
    "v": "p",
    "w": "A",
    "x": "e",
    "y": "2",
    "z": "t"
}

export const REVERSE_CHAR_MAPPING = {
    "0": "I",
    "2": "y",
    "3": "m",
    "4": "A",
    "7": "d",
    "8": "L",
    "9": "o",
    "M": "B",
    "T": "C",
    "E": "D",
    "U": "E",
    "V": "F",
    "b": "G",
    "R": "H",
    "D": "J",
    "l": "K",
    "F": "M",
    "w": "N",
    "G": "O",
    "i": "P",
    "N": "Q",
    "X": "R",
    "j": "S",
    "v": "T",
    "H": "U",
    "n": "V",
    "f": "W",
    "Y": "X",
    "m": "Y",
    "C": "Z",
    "J": "a",
    "k": "b",
    "S": "c",
    "c": "e",
    "W": "f",
    "Z": "g",
    "h": "h",
    "q": "i",
    "K": "j",
    "r": "k",
    "O": "l",
    "u": "n",
    "o": "p",
    "L": "q",
    "B": "r",
    "y": "s",
    "P": "t",
    "I": "u",
    "p": "v",
    "A": "w",
    "e": "x",
    "t": "z"
}


export const AGE_MAPPING = {
    "1.0": "WB5t",
    "1.1": "aRvu",
    "1.2": "e4sI",
    "1.3": "0PEC",
    "1.4": "GypA",
    "1.5": "8VTR",
    "1.6": "EEE5",
    "1.7": "RRFY",
    "1.8": "5wVf",
    "1.9": "1j3A",
    "2.0": "GLq1",
    "2.1": "Tsrm",
    "2.2": "LmGQ",
    "2.3": "Wiud",
    "2.4": "B7Zv",
    "2.5": "EtQO",
    "2.6": "8XmN",
    "2.7": "Hw5G",
    "2.8": "u9pb",
    "2.9": "p0eG",
    "3.0": "EeqP",
    "3.1": "plo1",
    "3.2": "lInL",
    "3.3": "kHZE",
    "3.4": "Qlp6",
    "3.5": "xeNZ",
    "3.6": "Mtqi",
    "3.7": "93Dw",
    "3.8": "4NW2",
    "3.9": "0i6k",
    "4.0": "rNTO",
    "4.1": "kfQl",
    "4.2": "Sf4J",
    "4.3": "6W0I",
    "4.4": "ZRNk",
    "4.5": "cpNm",
    "4.6": "yg4a",
    "4.7": "FKoP",
    "4.8": "UfNF",
    "4.9": "opRQ",
    "5.0": "Z01k",
    "5.1": "KJFT",
    "5.2": "p06T",
    "5.3": "0puu",
    "5.4": "9sXD",
    "5.5": "dDws",
    "5.6": "cuRa",
    "5.7": "vHX2",
    "5.8": "FPSh",
    "5.9": "VS1t",
    "6.0": "KdHk",
    "6.1": "VFK8",
    "6.2": "QHpH",
    "6.3": "r6bK",
    "6.4": "ZXt4",
    "6.5": "Vv6e",
    "6.6": "OauG",
    "6.7": "ymxq",
    "6.8": "stmQ",
    "6.9": "gq2h",
    "7.0": "pkPV",
    "7.1": "N7W2",
    "7.2": "5xjY",
    "7.3": "2Cb8",
    "7.4": "TuWX",
    "7.5": "tfZ7",
    "7.6": "ihwb",
    "7.7": "Gt8V",
    "7.8": "uBAU",
    "7.9": "ACB1",
    "8.0": "BmCV",
    "8.1": "jo5S",
    "8.2": "LM38",
    "8.3": "Nvt2",
    "8.4": "mtEz",
    "8.5": "N2GO",
    "8.6": "BRUm",
    "8.7": "SzEE",
    "8.8": "SDek",
    "8.9": "SAcl",
    "9.0": "qxPM",
    "9.1": "uQRP",
    "9.2": "7elx",
    "9.3": "ynG2",
    "9.4": "kzCP",
    "9.5": "PbcI",
    "9.6": "KGIS",
    "9.7": "7qrI",
    "9.8": "C2KM",
    "9.9": "tqY3",
    "10.0": "brWT",
    "10.1": "MCe3",
    "10.2": "o4My",
    "10.3": "kgAv",
    "10.4": "L9vv",
    "10.5": "CzRc",
    "10.6": "PDax",
    "10.7": "RyWX",
    "10.8": "6yuc",
    "10.9": "qcL3",
    "11.0": "JyEy",
    "11.1": "zqT0",
    "11.2": "s4wm",
    "11.3": "vtXN",
    "11.4": "Ar3r",
    "11.5": "Wyam",
    "11.6": "BBX5",
    "11.7": "rlVc",
    "11.8": "0AaJ",
    "11.9": "keWI",
    "12.0": "UlLc",
    "12.1": "ydmD",
    "12.2": "awV3",
    "12.3": "JvYq",
    "12.4": "UeeS",
    "12.5": "cfu6",
    "12.6": "Q3U4",
    "12.7": "qOI0",
    "12.8": "iddW",
    "12.9": "vK1W",
    "13.0": "3NGK",
    "13.1": "GxcP",
    "13.2": "QOaV",
    "13.3": "thvs",
    "13.4": "XIRl",
    "13.5": "CjSx",
    "13.6": "Mroq",
    "13.7": "anS7",
    "13.8": "wW9f",
    "13.9": "zvnm",
    "14.0": "DWeS",
    "14.1": "Wc8A",
    "14.2": "ZzV3",
    "14.3": "WF4h",
    "14.4": "OZRl",
    "14.5": "znk1",
    "14.6": "zE8K",
    "14.7": "9qPS",
    "14.8": "e5Gc",
    "14.9": "0Pd6",
    "15.0": "EIii",
    "15.1": "lcTZ",
    "15.2": "YZgd",
    "15.3": "4brn",
    "15.4": "9f5Y",
    "15.5": "jtQz",
    "15.6": "BvaR",
    "15.7": "bmoZ",
    "15.8": "6l8h",
    "15.9": "YkG6",
    "16.0": "tVuu",
    "16.1": "LQUB",
    "16.2": "HkJu",
    "16.3": "wtQr",
    "16.4": "NVJr",
    "16.5": "VRBu",
    "16.6": "T8Ii",
    "16.7": "0qvT",
    "16.8": "anPS",
    "16.9": "qvY2",
    "17.0": "yj3Y",
    "17.1": "1UIS",
    "17.2": "wcfI",
    "17.3": "ldLE",
    "17.4": "GlGf",
    "17.5": "Gjjw",
    "17.6": "1dCa",
    "17.7": "pf3T",
    "17.8": "OJmm",
    "17.9": "8a6q",
    "18.0": "Tf12",
    "18.1": "ZbCq",
    "18.2": "HEeL",
    "18.3": "YchQ",
    "18.4": "YI4U",
    "18.5": "NWf3",
    "18.6": "cLQ9",
    "18.7": "uOIi",
    "18.8": "eWqC",
    "18.9": "Zfst",
    "19.0": "o4ry",
    "19.1": "7wCy",
    "19.2": "rfIh",
    "19.3": "JqQV",
    "19.4": "SHHB",
    "19.5": "3cOY",
    "19.6": "fmLe",
    "19.7": "h6dE",
    "19.8": "78mb",
    "19.9": "AjLK",
    "20.0": "gXlA",
    "20.1": "ZmEU",
    "20.2": "EL0k",
    "20.3": "r1Ol",
    "20.4": "YUgB",
    "20.5": "sKPw",
    "20.6": "4x2D",
    "20.7": "99kH",
    "20.8": "hU1I",
    "20.9": "KmTz",
    "21.0": "EcJG",
    "21.1": "rqb2",
    "21.2": "UFc5",
    "21.3": "8Jsq",
    "21.4": "oah2",
    "21.5": "2Kyq",
    "21.6": "Tnxd",
    "21.7": "nFZf",
    "21.8": "WWCv",
    "21.9": "9F96",
    "22.0": "q2zV",
    "22.1": "Gsje",
    "22.2": "9kUE",
    "22.3": "tI2H",
    "22.4": "RAfB",
    "22.5": "vD4w",
    "22.6": "iwcl",
    "22.7": "dD3u",
    "22.8": "6BYJ",
    "22.9": "ulZR",
    "23.0": "G8Ts",
    "23.1": "lCzn",
    "23.2": "dss9",
    "23.3": "ayOd",
    "23.4": "Wyou",
    "23.5": "eHjp",
    "23.6": "6Ymy",
    "23.7": "Km8n",
    "23.8": "osTt",
    "23.9": "msbW",
    "24.0": "Cxg4",
    "24.1": "uvM7",
    "24.2": "fnw7",
    "24.3": "o4Fk",
    "24.4": "17eA",
    "24.5": "801t",
    "24.6": "9ocV",
    "24.7": "To36",
    "24.8": "jxzM",
    "24.9": "Fn9F",
    "25.0": "ruiA",
    "25.1": "dVSE",
    "25.2": "LUOq",
    "25.3": "JiPP",
    "25.4": "Gcjw",
    "25.5": "8hrf",
    "25.6": "mgwy",
    "25.7": "Xg1w",
    "25.8": "sW7O",
    "25.9": "rcBF",
    "26.0": "pd3L",
    "26.1": "mKTj",
    "26.2": "7FSz",
    "26.3": "Kg4m",
    "26.4": "Z98X",
    "26.5": "5CSM",
    "26.6": "ULzz",
    "26.7": "fCjT",
    "26.8": "zkti",
    "26.9": "JK0w",
    "27.0": "oXfE",
    "27.1": "Y4kO",
    "27.2": "AV95",
    "27.3": "IHt3",
    "27.4": "w6oo",
    "27.5": "kjXr",
    "27.6": "4MAK",
    "27.7": "fmuL",
    "27.8": "A5ss",
    "27.9": "dWwF",
    "28.0": "iJwU",
    "28.1": "2Bxx",
    "28.2": "1lpw",
    "28.3": "vdsp",
    "28.4": "mq2f",
    "28.5": "yCIx",
    "28.6": "UJvJ",
    "28.7": "11mO",
    "28.8": "uedY",
    "28.9": "6CO2",
    "29.0": "lptS",
    "29.1": "7D9e",
    "29.2": "aZCY",
    "29.3": "wtRj",
    "29.4": "ET3I",
    "29.5": "n6lu",
    "29.6": "J7o0",
    "29.7": "sA1T",
    "29.8": "XxGw",
    "29.9": "9N2f",
    "30.0": "xtNv",
    "30.1": "BrTI",
    "30.2": "BrmB",
    "30.3": "28Dn",
    "30.4": "lU0M",
    "30.5": "dxgF",
    "30.6": "DxPj",
    "30.7": "Z2ay",
    "30.8": "ma6D",
    "30.9": "trpm",
    "31.0": "STay",
    "31.1": "EsJ6",
    "31.2": "tii2",
    "31.3": "IEYP",
    "31.4": "k22y",
    "31.5": "7uXj",
    "31.6": "1Xqa",
    "31.7": "HWxK",
    "31.8": "SNjB",
    "31.9": "qBqW",
    "32.0": "gQ7O",
    "32.1": "NxAA",
    "32.2": "YLjI",
    "32.3": "rzMz",
    "32.4": "YlcC",
    "32.5": "sif0",
    "32.6": "dE1s",
    "32.7": "WrBo",
    "32.8": "BLKe",
    "32.9": "nhob",
    "33.0": "taKS",
    "33.1": "T6p7",
    "33.2": "PkZt",
    "33.3": "vBEP",
    "33.4": "LjdV",
    "33.5": "iB5d",
    "33.6": "4uVY",
    "33.7": "I5pZ",
    "33.8": "iJIF",
    "33.9": "wnX2",
    "34.0": "MJFa",
    "34.1": "NdAG",
    "34.2": "HAjp",
    "34.3": "hs1S",
    "34.4": "Ytum",
    "34.5": "P5kD",
    "34.6": "4gyo",
    "34.7": "DMhB",
    "34.8": "7hR9",
    "34.9": "ms61",
    "35.0": "hPOp",
    "35.1": "XV5f",
    "35.2": "x3bA",
    "35.3": "U2DC",
    "35.4": "UnBl",
    "35.5": "MczJ",
    "35.6": "Or2K",
    "35.7": "YTKG",
    "35.8": "pvl6",
    "35.9": "r3tp",
    "36.0": "IIAP",
    "36.1": "91Of",
    "36.2": "pc6M",
    "36.3": "qxYk",
    "36.4": "szit",
    "36.5": "nlbO",
    "36.6": "x07j",
    "36.7": "RFSc",
    "36.8": "h9Kh",
    "36.9": "Dq6H",
    "37.0": "dow8",
    "37.1": "Iats",
    "37.2": "CVsA",
    "37.3": "J7fI",
    "37.4": "FXXa",
    "37.5": "IkHh",
    "37.6": "z51d",
    "37.7": "1gvy",
    "37.8": "y4Dh",
    "37.9": "UmSq",
    "38.0": "CNp8",
    "38.1": "hGjv",
    "38.2": "9qQU",
    "38.3": "kkiU",
    "38.4": "fvEx",
    "38.5": "ShBU",
    "38.6": "6tPF",
    "38.7": "LOUv",
    "38.8": "Y44O",
    "38.9": "PrY3",
    "39.0": "aXBK",
    "39.1": "t6rg",
    "39.2": "TnGz",
    "39.3": "2LUd",
    "39.4": "UueN",
    "39.5": "ihFx",
    "39.6": "ha5l",
    "39.7": "SYNu",
    "39.8": "aPSw",
    "39.9": "5cQd",
    "40.0": "VaRw",
    "40.1": "lxgX",
    "40.2": "XB23",
    "40.3": "2o1o",
    "40.4": "iV1D",
    "40.5": "RO0s",
    "40.6": "Jy4c",
    "40.7": "zYrt",
    "40.8": "CDuD",
    "40.9": "vcvr",
    "41.0": "jKmh",
    "41.1": "MP4m",
    "41.2": "OFKM",
    "41.3": "8xHa",
    "41.4": "prRu",
    "41.5": "3sfg",
    "41.6": "WH4Y",
    "41.7": "T36G",
    "41.8": "dHTE",
    "41.9": "Zkwj",
    "42.0": "33WV",
    "42.1": "xsF3",
    "42.2": "oJDl",
    "42.3": "82TJ",
    "42.4": "KLVv",
    "42.5": "sKSW",
    "42.6": "xXyt",
    "42.7": "fneL",
    "42.8": "m3MY",
    "42.9": "GYqG",
    "43.0": "zoSh",
    "43.1": "Aoy8",
    "43.2": "7ni6",
    "43.3": "XlLz",
    "43.4": "ci3k",
    "43.5": "vJpZ",
    "43.6": "OZ5W",
    "43.7": "HEsy",
    "43.8": "tBne",
    "43.9": "DqAr",
    "44.0": "zwyu",
    "44.1": "ryIJ",
    "44.2": "cmQx",
    "44.3": "QsO4",
    "44.4": "Qyrn",
    "44.5": "s7VK",
    "44.6": "r9fv",
    "44.7": "l0tD",
    "44.8": "0Blr",
    "44.9": "fKlV",
    "45.0": "jqDu",
    "45.1": "OPwG",
    "45.2": "yUYE",
    "45.3": "YsKU",
    "45.4": "sbOS",
    "45.5": "bfcP",
    "45.6": "pt3q",
    "45.7": "Pszn",
    "45.8": "TcZ3",
    "45.9": "CAaB",
    "46.0": "DzZJ",
    "46.1": "Dh4l",
    "46.2": "Ym72",
    "46.3": "m3Bx",
    "46.4": "jMie",
    "46.5": "GnCT",
    "46.6": "GD7c",
    "46.7": "SAwi",
    "46.8": "DBHH",
    "46.9": "fjLw",
    "47.0": "EZIF",
    "47.1": "Ebo3",
    "47.2": "0DyY",
    "47.3": "gQai",
    "47.4": "p1SL",
    "47.5": "I1aO",
    "47.6": "2xk4",
    "47.7": "p8kr",
    "47.8": "A12I",
    "47.9": "KnvQ",
    "48.0": "Zhnu",
    "48.1": "OVUk",
    "48.2": "pDF4",
    "48.3": "KB9J",
    "48.4": "wlKD",
    "48.5": "x3ag",
    "48.6": "bpMy",
    "48.7": "m0qt",
    "48.8": "SrTt",
    "48.9": "k8kn",
    "49.0": "4dD9",
    "49.1": "5IKJ",
    "49.2": "ECkF",
    "49.3": "c9Ax",
    "49.4": "HUdH",
    "49.5": "jcSF",
    "49.6": "JiNl",
    "49.7": "vvE1",
    "49.8": "Ofh9",
    "49.9": "6twd",
    "50.0": "ExmG",
    "50.1": "Vr3F",
    "50.2": "hdqN",
    "50.3": "6M7N",
    "50.4": "6omi",
    "50.5": "sVF3",
    "50.6": "3WiY",
    "50.7": "XzCS",
    "50.8": "i6TU",
    "50.9": "2YwN",
    "51.0": "6fl8",
    "51.1": "qNDF",
    "51.2": "9Nv9",
    "51.3": "bIUG",
    "51.4": "5LGf",
    "51.5": "PgT7",
    "51.6": "3RD6",
    "51.7": "APAD",
    "51.8": "mNeb",
    "51.9": "MGyZ",
    "52.0": "zEoo",
    "52.1": "nGmm",
    "52.2": "Rz6s",
    "52.3": "x9O6",
    "52.4": "jZVX",
    "52.5": "ixSy",
    "52.6": "LdLP",
    "52.7": "sKqp",
    "52.8": "VWgx",
    "52.9": "KdLV",
    "53.0": "vj1x",
    "53.1": "89qA",
    "53.2": "XP96",
    "53.3": "RRtN",
    "53.4": "Fm2D",
    "53.5": "EWa1",
    "53.6": "wMbp",
    "53.7": "rOpL",
    "53.8": "E8VF",
    "53.9": "1NXH",
    "54.0": "OH2g",
    "54.1": "cNJd",
    "54.2": "iDFD",
    "54.3": "41Xq",
    "54.4": "JhvT",
    "54.5": "68g3",
    "54.6": "qxIv",
    "54.7": "EBVk",
    "54.8": "ZyoM",
    "54.9": "lYUO",
    "55.0": "yjtB",
    "55.1": "jsvk",
    "55.2": "xqkA",
    "55.3": "hTlL",
    "55.4": "twTG",
    "55.5": "Hv8w",
    "55.6": "AAM0",
    "55.7": "Qslx",
    "55.8": "j8vd",
    "55.9": "iK6I",
    "56.0": "BO8T",
    "56.1": "P78f",
    "56.2": "EjxW",
    "56.3": "8ha4",
    "56.4": "HiD0",
    "56.5": "9xHS",
    "56.6": "MA1W",
    "56.7": "bbOg",
    "56.8": "v1ve",
    "56.9": "yaKV",
    "57.0": "9FOd",
    "57.1": "bKdm",
    "57.2": "v7DJ",
    "57.3": "XoVX",
    "57.4": "ri4U",
    "57.5": "d1fU",
    "57.6": "fspo",
    "57.7": "MXOr",
    "57.8": "bDY2",
    "57.9": "xcBK",
    "58.0": "0t8K",
    "58.1": "BfmD",
    "58.2": "RsMa",
    "58.3": "tMth",
    "58.4": "ows2",
    "58.5": "ruxH",
    "58.6": "GZyV",
    "58.7": "NqD8",
    "58.8": "V5E2",
    "58.9": "BK2K",
    "59.0": "Cfaj",
    "59.1": "Fdvg",
    "59.2": "p1zJ",
    "59.3": "OeBk",
    "59.4": "3l9j",
    "59.5": "EsTd",
    "59.6": "AL44",
    "59.7": "8sHp",
    "59.8": "D2ZI",
    "59.9": "mO9t",
    "60.0": "2PUj",
    "60.1": "vewi",
    "60.2": "bLZ3",
    "60.3": "Vlf6",
    "60.4": "jNDQ",
    "60.5": "XrQS",
    "60.6": "QB6u",
    "60.7": "HEpu",
    "60.8": "p580",
    "60.9": "Y3Dm",
    "61.0": "mOdZ",
    "61.1": "HkyM",
    "61.2": "fMWW",
    "61.3": "wFh2",
    "61.4": "yXwg",
    "61.5": "vL71",
    "61.6": "OFD8",
    "61.7": "oQTc",
    "61.8": "SNXQ",
    "61.9": "lHXY",
    "62.0": "1URd",
    "62.1": "gvcb",
    "62.2": "Gn1A",
    "62.3": "BU64",
    "62.4": "OXi7",
    "62.5": "sXJm",
    "62.6": "7klV",
    "62.7": "aLMj",
    "62.8": "R0YI",
    "62.9": "zIBY",
    "63.0": "UYvl",
    "63.1": "35Z5",
    "63.2": "kyVm",
    "63.3": "WXQ2",
    "63.4": "2tW6",
    "63.5": "SnRA",
    "63.6": "HRUC",
    "63.7": "BKlP",
    "63.8": "XkOA",
    "63.9": "rGiY",
    "64.0": "BjmK",
    "64.1": "16sE",
    "64.2": "DQ5D",
    "64.3": "iFuw",
    "64.4": "DRzw",
    "64.5": "O9HC",
    "64.6": "yZ7M",
    "64.7": "Xu9L",
    "64.8": "BBlE",
    "64.9": "P1AT",
    "65.0": "Q2uL",
    "65.1": "5l44",
    "65.2": "rhwy",
    "65.3": "CF7S",
    "65.4": "qgB0",
    "65.5": "qeva",
    "65.6": "Gvj8",
    "65.7": "PA3V",
    "65.8": "ydlc",
    "65.9": "TGoK",
    "66.0": "FNrY",
    "66.1": "RHCI",
    "66.2": "iuU3",
    "66.3": "noGS",
    "66.4": "bMTi",
    "66.5": "sNAt",
    "66.6": "YqtF",
    "66.7": "2o6b",
    "66.8": "aC6e",
    "66.9": "nOBJ",
    "67.0": "AdG5",
    "67.1": "dUon",
    "67.2": "pubZ",
    "67.3": "iJ7q",
    "67.4": "rjYG",
    "67.5": "Myvf",
    "67.6": "3hK2",
    "67.7": "q2RZ",
    "67.8": "yOGg",
    "67.9": "618r",
    "68.0": "z9YN",
    "68.1": "9KEY",
    "68.2": "dejU",
    "68.3": "m0I4",
    "68.4": "pIOP",
    "68.5": "TDXH",
    "68.6": "Zy8t",
    "68.7": "dcEH",
    "68.8": "FYP6",
    "68.9": "cTTL",
    "69.0": "rLg0",
    "69.1": "afkE",
    "69.2": "6R1v",
    "69.3": "J7A3",
    "69.4": "XHnR",
    "69.5": "OVZ6",
    "69.6": "oWmG",
    "69.7": "ItLB",
    "69.8": "nvBw",
    "69.9": "rvM4",
    "70.0": "1Pj3",
    "70.1": "RXLC",
    "70.2": "MGGw",
    "70.3": "HoAJ",
    "70.4": "lqIs",
    "70.5": "pKxL",
    "70.6": "vdH6",
    "70.7": "llkA",
    "70.8": "hxmu",
    "70.9": "9w3F",
    "71.0": "kBJy",
    "71.1": "09Cg",
    "71.2": "MUiV",
    "71.3": "hK6g",
    "71.4": "55Vx",
    "71.5": "LYqb",
    "71.6": "bMmu",
    "71.7": "DE7m",
    "71.8": "PjFD",
    "71.9": "cNSN",
    "72.0": "jEiD",
    "72.1": "xzjL",
    "72.2": "QR6a",
    "72.3": "be56",
    "72.4": "OQ0p",
    "72.5": "iOfL",
    "72.6": "hrc0",
    "72.7": "BLXk",
    "72.8": "emES",
    "72.9": "V4xW",
    "73.0": "SKmy",
    "73.1": "Ttqn",
    "73.2": "vyGP",
    "73.3": "wfAC",
    "73.4": "CykJ",
    "73.5": "JpqV",
    "73.6": "RQIo",
    "73.7": "DZIT",
    "73.8": "r3fU",
    "73.9": "iA1S",
    "74.0": "3Zw8",
    "74.1": "U4wV",
    "74.2": "fbyJ",
    "74.3": "iZRI",
    "74.4": "he9G",
    "74.5": "BtMd",
    "74.6": "zqC6",
    "74.7": "XqKU",
    "74.8": "N1pd",
    "74.9": "8R09",
    "75.0": "1PbK",
    "75.1": "VRtM",
    "75.2": "GeG2",
    "75.3": "q9wl",
    "75.4": "t1uP",
    "75.5": "TBeZ",
    "75.6": "Rm6G",
    "75.7": "DTzO",
    "75.8": "eSaw",
    "75.9": "Ccr4",
    "76.0": "SbaF",
    "76.1": "loxy",
    "76.2": "9NTz",
    "76.3": "tSI2",
    "76.4": "SksZ",
    "76.5": "v1sr",
    "76.6": "MImN",
    "76.7": "0wAa",
    "76.8": "3BTA",
    "76.9": "7Dkc",
    "77.0": "PLqD",
    "77.1": "a5B8",
    "77.2": "BUQv",
    "77.3": "Mwjf",
    "77.4": "hulH",
    "77.5": "qZun",
    "77.6": "6o2E",
    "77.7": "usWa",
    "77.8": "IJtP",
    "77.9": "RlAf",
    "78.0": "BLqh",
    "78.1": "nW37",
    "78.2": "HhjI",
    "78.3": "5WUO",
    "78.4": "CgF8",
    "78.5": "5xNX",
    "78.6": "2sHY",
    "78.7": "b4GK",
    "78.8": "g8uE",
    "78.9": "LrgV",
    "79.0": "w6bH",
    "79.1": "8RJI",
    "79.2": "CY2t",
    "79.3": "1JsN",
    "79.4": "b7YH",
    "79.5": "zHIl",
    "79.6": "ezLk",
    "79.7": "JDNX",
    "79.8": "4qdc",
    "79.9": "uGAr",
    "80.0": "A0dC",
    "80.1": "1yTx",
    "80.2": "Co1b",
    "80.3": "P2C4",
    "80.4": "9b97",
    "80.5": "SaFq",
    "80.6": "RHNj",
    "80.7": "rOmL",
    "80.8": "I6Lm",
    "80.9": "IQk0",
    "81.0": "RHv0",
    "81.1": "jvhs",
    "81.2": "KnaS",
    "81.3": "qgCU",
    "81.4": "b9Q9",
    "81.5": "XnxH",
    "81.6": "WBJZ",
    "81.7": "3lkp",
    "81.8": "O5sP",
    "81.9": "QVsV",
    "82.0": "r29o",
    "82.1": "Moo5",
    "82.2": "4nSW",
    "82.3": "ko65",
    "82.4": "kqt5",
    "82.5": "ynA5",
    "82.6": "Nyhf",
    "82.7": "4qzO",
    "82.8": "31iS",
    "82.9": "CvJ3",
    "83.0": "2VhW",
    "83.1": "Fb4t",
    "83.2": "GxYG",
    "83.3": "6ueM",
    "83.4": "akCE",
    "83.5": "U1zK",
    "83.6": "8Oky",
    "83.7": "uvo9",
    "83.8": "EBcm",
    "83.9": "r3pp",
    "84.0": "dRd0",
    "84.1": "vQVh",
    "84.2": "r6l8",
    "84.3": "v8TP",
    "84.4": "YG9e",
    "84.5": "Lbqc",
    "84.6": "ooA8",
    "84.7": "wYF1",
    "84.8": "9Eu6",
    "84.9": "iMY4",
    "85.0": "o2rl",
    "85.1": "3kp6",
    "85.2": "oJzX",
    "85.3": "oKBD",
    "85.4": "DFnI",
    "85.5": "psq6",
    "85.6": "YlQS",
    "85.7": "bEj5",
    "85.8": "FMNC",
    "85.9": "LycE",
    "86.0": "8FDf",
    "86.1": "ntcl",
    "86.2": "rSbR",
    "86.3": "nkNa",
    "86.4": "DtFP",
    "86.5": "q1xl",
    "86.6": "1xSX",
    "86.7": "6LL4",
    "86.8": "fONU",
    "86.9": "z88H",
    "87.0": "toRv",
    "87.1": "SnZ2",
    "87.2": "O9al",
    "87.3": "FUWs",
    "87.4": "JUfO",
    "87.5": "v6PD",
    "87.6": "UCQD",
    "87.7": "iCkN",
    "87.8": "UFsR",
    "87.9": "EOAO",
    "88.0": "NdR4",
    "88.1": "qTWU",
    "88.2": "L1PW",
    "88.3": "cjVT",
    "88.4": "plVi",
    "88.5": "NXf2",
    "88.6": "uQWb",
    "88.7": "EtB9",
    "88.8": "biWA",
    "88.9": "ww9K",
    "89.0": "ie1d",
    "89.1": "VrR2",
    "89.2": "nCok",
    "89.3": "bnPK",
    "89.4": "guDU",
    "89.5": "1bWd",
    "89.6": "IDdm",
    "89.7": "rRmQ",
    "89.8": "5Z0y",
    "89.9": "s8aZ",
    "90.0": "q5WJ",
    "90.1": "0ZSd",
    "90.2": "gK2J",
    "90.3": "r27w",
    "90.4": "8WKe",
    "90.5": "IXkL",
    "90.6": "k8Nm",
    "90.7": "NsA9",
    "90.8": "E7WH",
    "90.9": "CtGw",
    "91.0": "DkbU",
    "91.1": "iDYN",
    "91.2": "m7ci",
    "91.3": "qI2a",
    "91.4": "JlHl",
    "91.5": "msOo",
    "91.6": "LVf7",
    "91.7": "Ytpl",
    "91.8": "CnqZ",
    "91.9": "vVre",
    "92.0": "OFZB",
    "92.1": "9BhT",
    "92.2": "Wfr1",
    "92.3": "Sznv",
    "92.4": "aoVI",
    "92.5": "5wQT",
    "92.6": "3D0P",
    "92.7": "ucyh",
    "92.8": "AZh9",
    "92.9": "y4X0",
    "93.0": "hbso",
    "93.1": "fpg2",
    "93.2": "UxhY",
    "93.3": "aFHF",
    "93.4": "lUUH",
    "93.5": "bk39",
    "93.6": "YX8d",
    "93.7": "IxRe",
    "93.8": "CZ4a",
    "93.9": "4XYN",
    "94.0": "IzoG",
    "94.1": "a6cm",
    "94.2": "dJvU",
    "94.3": "06zi",
    "94.4": "jsNj",
    "94.5": "XkTt",
    "94.6": "RMMR",
    "94.7": "fTlJ",
    "94.8": "OFAD",
    "94.9": "yR7h",
    "95.0": "M8vY",
    "95.1": "lHAv",
    "95.2": "oWmm",
    "95.3": "SJQR",
    "95.4": "CNGp",
    "95.5": "6oRP",
    "95.6": "kUGG",
    "95.7": "bllW",
    "95.8": "IfEd",
    "95.9": "gyhk",
    "96.0": "lTVd",
    "96.1": "nbYq",
    "96.2": "o0pG",
    "96.3": "z2kV",
    "96.4": "FYG5",
    "96.5": "Veyw",
    "96.6": "a2wN",
    "96.7": "9ENk",
    "96.8": "cXq1",
    "96.9": "aOc5",
    "97.0": "5Z06",
    "97.1": "cfpG",
    "97.2": "zKUy",
    "97.3": "ZSye",
    "97.4": "D8oU",
    "97.5": "Plpc",
    "97.6": "FF8K",
    "97.7": "NxI9",
    "97.8": "7ain",
    "97.9": "PLEk",
    "98.0": "MlyX",
    "98.1": "0MuR",
    "98.2": "LE2T",
    "98.3": "jWDm",
    "98.4": "33YF",
    "98.5": "VADT",
    "98.6": "bFJ4",
    "98.7": "vLFW",
    "98.8": "oIic",
    "98.9": "psNk",
    "99.0": "FLpr",
    "99.1": "9AI9",
    "99.2": "pTck",
    "99.3": "kYdc",
    "99.4": "LaIo",
    "99.5": "woeD",
    "99.6": "8hiS",
    "99.7": "rGjt",
    "99.8": "UlJg",
    "99.9": "s7cX",
    "100.0": "VSod",
    "100.1": "7Hdi",
    "100.2": "lVFF",
    "100.3": "Hwts",
    "100.4": "cKEk",
    "100.5": "jsEq",
    "100.6": "41sg",
    "100.7": "Cxbp",
    "100.8": "WuHH",
    "100.9": "0BbZ",
    "101.0": "L7Ol",
    "101.1": "2hGO",
    "101.2": "Zaeq",
    "101.3": "0DVX",
    "101.4": "S1yz",
    "101.5": "euSq",
    "101.6": "IbNH",
    "101.7": "Ekbt",
    "101.8": "UaHo",
    "101.9": "2FqC",
    "102.0": "civL",
    "102.1": "7b1H",
    "102.2": "QVum",
    "102.3": "5AgX",
    "102.4": "cljj",
    "102.5": "YwzG",
    "102.6": "HdJl",
    "102.7": "qvRc",
    "102.8": "nDHu",
    "102.9": "csMI",
    "103.0": "OBX4",
    "103.1": "O1K7",
    "103.2": "vzyP",
    "103.3": "DnVl",
    "103.4": "EE77",
    "103.5": "vq1A",
    "103.6": "hasP",
    "103.7": "v6FY",
    "103.8": "xNUD",
    "103.9": "RO55",
    "104.0": "TAfx",
    "104.1": "5QS1",
    "104.2": "fJxN",
    "104.3": "4XA8",
    "104.4": "62Ry",
    "104.5": "v1dO",
    "104.6": "02BH",
    "104.7": "evNP",
    "104.8": "IijQ",
    "104.9": "llzs",
    "105.0": "uLAR",
    "105.1": "RLev",
    "105.2": "rEDD",
    "105.3": "9RLp",
    "105.4": "On0U",
    "105.5": "tfKY",
    "105.6": "Q5qE",
    "105.7": "PfJt",
    "105.8": "uwhD",
    "105.9": "MzQ3",
    "106.0": "4d1v",
    "106.1": "PMMm",
    "106.2": "AVE2",
    "106.3": "Oxf9",
    "106.4": "R0VA",
    "106.5": "jAHy",
    "106.6": "FyLD",
    "106.7": "R0Xm",
    "106.8": "BjTI",
    "106.9": "pkpk",
    "107.0": "JsXe",
    "107.1": "mTK0",
    "107.2": "V8f6",
    "107.3": "AZVc",
    "107.4": "OVng",
    "107.5": "K3cO",
    "107.6": "fuRV",
    "107.7": "7acQ",
    "107.8": "GJnE",
    "107.9": "htvW",
    "108.0": "A2tN",
    "108.1": "kiEw",
    "108.2": "5uG5",
    "108.3": "Yny9",
    "108.4": "4P0Z",
    "108.5": "536C",
    "108.6": "2ZuH",
    "108.7": "G8NN",
    "108.8": "s1Ou",
    "108.9": "nEYS",
    "109.0": "pnL5",
    "109.1": "hNHR",
    "109.2": "VHvW",
    "109.3": "ocN9",
    "109.4": "2YB7",
    "109.5": "vm9Y",
    "109.6": "Royn",
    "109.7": "dPlK",
    "109.8": "Rv6E",
    "109.9": "qZot",
    "110.0": "ZQ51",
    "110.1": "ok9W",
    "110.2": "kbx1",
    "110.3": "knCH",
    "110.4": "eh7Y",
    "110.5": "REr1",
    "110.6": "acMW",
    "110.7": "iz0H",
    "110.8": "esxy",
    "110.9": "tQtu",
    "111.0": "oiU5",
    "111.1": "0er9",
    "111.2": "Gyny",
    "111.3": "dlCB",
    "111.4": "yoJc",
    "111.5": "kepy",
    "111.6": "wHeM",
    "111.7": "VoYE",
    "111.8": "IpMZ",
    "111.9": "Aps8",
    "112.0": "fScy",
    "112.1": "D7Ei",
    "112.2": "6kir",
    "112.3": "TbTO",
    "112.4": "ghoP",
    "112.5": "odoi",
    "112.6": "zVJe",
    "112.7": "H9ET",
    "112.8": "wL01",
    "112.9": "6HVA",
    "113.0": "YoH7",
    "113.1": "mXpz",
    "113.2": "Ii7x",
    "113.3": "35Zm",
    "113.4": "JJfC",
    "113.5": "T1AR",
    "113.6": "aDeI",
    "113.7": "piOr",
    "113.8": "6sDu",
    "113.9": "QxpV",
    "114.0": "aoS1",
    "114.1": "3BMN",
    "114.2": "Ybsb",
    "114.3": "GmDS",
    "114.4": "zf1a",
    "114.5": "MT7O",
    "114.6": "q6Ew",
    "114.7": "Xv2u",
    "114.8": "n90V",
    "114.9": "QvWQ",
    "115.0": "yjOV",
    "115.1": "mnxq",
    "115.2": "sru7",
    "115.3": "Zcnx",
    "115.4": "unJw",
    "115.5": "Z8U4",
    "115.6": "wUv6",
    "115.7": "HX9W",
    "115.8": "9pRD",
    "115.9": "oD56",
    "116.0": "4JFV",
    "116.1": "zK03",
    "116.2": "TyAQ",
    "116.3": "PKZQ",
    "116.4": "YH7n",
    "116.5": "lM09",
    "116.6": "vTT1",
    "116.7": "WnJr",
    "116.8": "c8jq",
    "116.9": "1Um2",
    "117.0": "dFJ5",
    "117.1": "MoII",
    "117.2": "5waK",
    "117.3": "uwCV",
    "117.4": "IH9I",
    "117.5": "bm6Q",
    "117.6": "un2X",
    "117.7": "qzjD",
    "117.8": "Wcmq",
    "117.9": "zNwS",
    "118.0": "3wAh",
    "118.1": "vvYM",
    "118.2": "AbAc",
    "118.3": "twhc",
    "118.4": "MrhF",
    "118.5": "M3B2",
    "118.6": "J2EK",
    "118.7": "EsX1",
    "118.8": "v8nt",
    "118.9": "8Gau",
    "119.0": "H3Uv",
    "119.1": "fTV0",
    "119.2": "jeuV",
    "119.3": "qP1W",
    "119.4": "OmGE",
    "119.5": "ZwOa",
    "119.6": "TxvK",
    "119.7": "hM0a",
    "119.8": "qEMW",
    "119.9": "HCtZ",
    "120.0": "Np7V",
    "120.1": "OSHi",
    "120.2": "hxgV",
    "120.3": "UfSq",
    "120.4": "7xCx",
    "120.5": "nSpU",
    "120.6": "GNR4",
    "120.7": "5ICp",
    "120.8": "cRDK",
    "120.9": "MVsy"
}

export const DECRYPT_AGE_MAPPING = {
    "WB5t": "1.0",
    "aRvu": "1.1",
    "e4sI": "1.2",
    "0PEC": "1.3",
    "GypA": "1.4",
    "8VTR": "1.5",
    "EEE5": "1.6",
    "RRFY": "1.7",
    "5wVf": "1.8",
    "1j3A": "1.9",
    "GLq1": "2.0",
    "Tsrm": "2.1",
    "LmGQ": "2.2",
    "Wiud": "2.3",
    "B7Zv": "2.4",
    "EtQO": "2.5",
    "8XmN": "2.6",
    "Hw5G": "2.7",
    "u9pb": "2.8",
    "p0eG": "2.9",
    "EeqP": "3.0",
    "plo1": "3.1",
    "lInL": "3.2",
    "kHZE": "3.3",
    "Qlp6": "3.4",
    "xeNZ": "3.5",
    "Mtqi": "3.6",
    "93Dw": "3.7",
    "4NW2": "3.8",
    "0i6k": "3.9",
    "rNTO": "4.0",
    "kfQl": "4.1",
    "Sf4J": "4.2",
    "6W0I": "4.3",
    "ZRNk": "4.4",
    "cpNm": "4.5",
    "yg4a": "4.6",
    "FKoP": "4.7",
    "UfNF": "4.8",
    "opRQ": "4.9",
    "Z01k": "5.0",
    "KJFT": "5.1",
    "p06T": "5.2",
    "0puu": "5.3",
    "9sXD": "5.4",
    "dDws": "5.5",
    "cuRa": "5.6",
    "vHX2": "5.7",
    "FPSh": "5.8",
    "VS1t": "5.9",
    "KdHk": "6.0",
    "VFK8": "6.1",
    "QHpH": "6.2",
    "r6bK": "6.3",
    "ZXt4": "6.4",
    "Vv6e": "6.5",
    "OauG": "6.6",
    "ymxq": "6.7",
    "stmQ": "6.8",
    "gq2h": "6.9",
    "pkPV": "7.0",
    "N7W2": "7.1",
    "5xjY": "7.2",
    "2Cb8": "7.3",
    "TuWX": "7.4",
    "tfZ7": "7.5",
    "ihwb": "7.6",
    "Gt8V": "7.7",
    "uBAU": "7.8",
    "ACB1": "7.9",
    "BmCV": "8.0",
    "jo5S": "8.1",
    "LM38": "8.2",
    "Nvt2": "8.3",
    "mtEz": "8.4",
    "N2GO": "8.5",
    "BRUm": "8.6",
    "SzEE": "8.7",
    "SDek": "8.8",
    "SAcl": "8.9",
    "qxPM": "9.0",
    "uQRP": "9.1",
    "7elx": "9.2",
    "ynG2": "9.3",
    "kzCP": "9.4",
    "PbcI": "9.5",
    "KGIS": "9.6",
    "7qrI": "9.7",
    "C2KM": "9.8",
    "tqY3": "9.9",
    "brWT": "10.0",
    "MCe3": "10.1",
    "o4My": "10.2",
    "kgAv": "10.3",
    "L9vv": "10.4",
    "CzRc": "10.5",
    "PDax": "10.6",
    "RyWX": "10.7",
    "6yuc": "10.8",
    "qcL3": "10.9",
    "JyEy": "11.0",
    "zqT0": "11.1",
    "s4wm": "11.2",
    "vtXN": "11.3",
    "Ar3r": "11.4",
    "Wyam": "11.5",
    "BBX5": "11.6",
    "rlVc": "11.7",
    "0AaJ": "11.8",
    "keWI": "11.9",
    "UlLc": "12.0",
    "ydmD": "12.1",
    "awV3": "12.2",
    "JvYq": "12.3",
    "UeeS": "12.4",
    "cfu6": "12.5",
    "Q3U4": "12.6",
    "qOI0": "12.7",
    "iddW": "12.8",
    "vK1W": "12.9",
    "3NGK": "13.0",
    "GxcP": "13.1",
    "QOaV": "13.2",
    "thvs": "13.3",
    "XIRl": "13.4",
    "CjSx": "13.5",
    "Mroq": "13.6",
    "anS7": "13.7",
    "wW9f": "13.8",
    "zvnm": "13.9",
    "DWeS": "14.0",
    "Wc8A": "14.1",
    "ZzV3": "14.2",
    "WF4h": "14.3",
    "OZRl": "14.4",
    "znk1": "14.5",
    "zE8K": "14.6",
    "9qPS": "14.7",
    "e5Gc": "14.8",
    "0Pd6": "14.9",
    "EIii": "15.0",
    "lcTZ": "15.1",
    "YZgd": "15.2",
    "4brn": "15.3",
    "9f5Y": "15.4",
    "jtQz": "15.5",
    "BvaR": "15.6",
    "bmoZ": "15.7",
    "6l8h": "15.8",
    "YkG6": "15.9",
    "tVuu": "16.0",
    "LQUB": "16.1",
    "HkJu": "16.2",
    "wtQr": "16.3",
    "NVJr": "16.4",
    "VRBu": "16.5",
    "T8Ii": "16.6",
    "0qvT": "16.7",
    "anPS": "16.8",
    "qvY2": "16.9",
    "yj3Y": "17.0",
    "1UIS": "17.1",
    "wcfI": "17.2",
    "ldLE": "17.3",
    "GlGf": "17.4",
    "Gjjw": "17.5",
    "1dCa": "17.6",
    "pf3T": "17.7",
    "OJmm": "17.8",
    "8a6q": "17.9",
    "Tf12": "18.0",
    "ZbCq": "18.1",
    "HEeL": "18.2",
    "YchQ": "18.3",
    "YI4U": "18.4",
    "NWf3": "18.5",
    "cLQ9": "18.6",
    "uOIi": "18.7",
    "eWqC": "18.8",
    "Zfst": "18.9",
    "o4ry": "19.0",
    "7wCy": "19.1",
    "rfIh": "19.2",
    "JqQV": "19.3",
    "SHHB": "19.4",
    "3cOY": "19.5",
    "fmLe": "19.6",
    "h6dE": "19.7",
    "78mb": "19.8",
    "AjLK": "19.9",
    "gXlA": "20.0",
    "ZmEU": "20.1",
    "EL0k": "20.2",
    "r1Ol": "20.3",
    "YUgB": "20.4",
    "sKPw": "20.5",
    "4x2D": "20.6",
    "99kH": "20.7",
    "hU1I": "20.8",
    "KmTz": "20.9",
    "EcJG": "21.0",
    "rqb2": "21.1",
    "UFc5": "21.2",
    "8Jsq": "21.3",
    "oah2": "21.4",
    "2Kyq": "21.5",
    "Tnxd": "21.6",
    "nFZf": "21.7",
    "WWCv": "21.8",
    "9F96": "21.9",
    "q2zV": "22.0",
    "Gsje": "22.1",
    "9kUE": "22.2",
    "tI2H": "22.3",
    "RAfB": "22.4",
    "vD4w": "22.5",
    "iwcl": "22.6",
    "dD3u": "22.7",
    "6BYJ": "22.8",
    "ulZR": "22.9",
    "G8Ts": "23.0",
    "lCzn": "23.1",
    "dss9": "23.2",
    "ayOd": "23.3",
    "Wyou": "23.4",
    "eHjp": "23.5",
    "6Ymy": "23.6",
    "Km8n": "23.7",
    "osTt": "23.8",
    "msbW": "23.9",
    "Cxg4": "24.0",
    "uvM7": "24.1",
    "fnw7": "24.2",
    "o4Fk": "24.3",
    "17eA": "24.4",
    "801t": "24.5",
    "9ocV": "24.6",
    "To36": "24.7",
    "jxzM": "24.8",
    "Fn9F": "24.9",
    "ruiA": "25.0",
    "dVSE": "25.1",
    "LUOq": "25.2",
    "JiPP": "25.3",
    "Gcjw": "25.4",
    "8hrf": "25.5",
    "mgwy": "25.6",
    "Xg1w": "25.7",
    "sW7O": "25.8",
    "rcBF": "25.9",
    "pd3L": "26.0",
    "mKTj": "26.1",
    "7FSz": "26.2",
    "Kg4m": "26.3",
    "Z98X": "26.4",
    "5CSM": "26.5",
    "ULzz": "26.6",
    "fCjT": "26.7",
    "zkti": "26.8",
    "JK0w": "26.9",
    "oXfE": "27.0",
    "Y4kO": "27.1",
    "AV95": "27.2",
    "IHt3": "27.3",
    "w6oo": "27.4",
    "kjXr": "27.5",
    "4MAK": "27.6",
    "fmuL": "27.7",
    "A5ss": "27.8",
    "dWwF": "27.9",
    "iJwU": "28.0",
    "2Bxx": "28.1",
    "1lpw": "28.2",
    "vdsp": "28.3",
    "mq2f": "28.4",
    "yCIx": "28.5",
    "UJvJ": "28.6",
    "11mO": "28.7",
    "uedY": "28.8",
    "6CO2": "28.9",
    "lptS": "29.0",
    "7D9e": "29.1",
    "aZCY": "29.2",
    "wtRj": "29.3",
    "ET3I": "29.4",
    "n6lu": "29.5",
    "J7o0": "29.6",
    "sA1T": "29.7",
    "XxGw": "29.8",
    "9N2f": "29.9",
    "xtNv": "30.0",
    "BrTI": "30.1",
    "BrmB": "30.2",
    "28Dn": "30.3",
    "lU0M": "30.4",
    "dxgF": "30.5",
    "DxPj": "30.6",
    "Z2ay": "30.7",
    "ma6D": "30.8",
    "trpm": "30.9",
    "STay": "31.0",
    "EsJ6": "31.1",
    "tii2": "31.2",
    "IEYP": "31.3",
    "k22y": "31.4",
    "7uXj": "31.5",
    "1Xqa": "31.6",
    "HWxK": "31.7",
    "SNjB": "31.8",
    "qBqW": "31.9",
    "gQ7O": "32.0",
    "NxAA": "32.1",
    "YLjI": "32.2",
    "rzMz": "32.3",
    "YlcC": "32.4",
    "sif0": "32.5",
    "dE1s": "32.6",
    "WrBo": "32.7",
    "BLKe": "32.8",
    "nhob": "32.9",
    "taKS": "33.0",
    "T6p7": "33.1",
    "PkZt": "33.2",
    "vBEP": "33.3",
    "LjdV": "33.4",
    "iB5d": "33.5",
    "4uVY": "33.6",
    "I5pZ": "33.7",
    "iJIF": "33.8",
    "wnX2": "33.9",
    "MJFa": "34.0",
    "NdAG": "34.1",
    "HAjp": "34.2",
    "hs1S": "34.3",
    "Ytum": "34.4",
    "P5kD": "34.5",
    "4gyo": "34.6",
    "DMhB": "34.7",
    "7hR9": "34.8",
    "ms61": "34.9",
    "hPOp": "35.0",
    "XV5f": "35.1",
    "x3bA": "35.2",
    "U2DC": "35.3",
    "UnBl": "35.4",
    "MczJ": "35.5",
    "Or2K": "35.6",
    "YTKG": "35.7",
    "pvl6": "35.8",
    "r3tp": "35.9",
    "IIAP": "36.0",
    "91Of": "36.1",
    "pc6M": "36.2",
    "qxYk": "36.3",
    "szit": "36.4",
    "nlbO": "36.5",
    "x07j": "36.6",
    "RFSc": "36.7",
    "h9Kh": "36.8",
    "Dq6H": "36.9",
    "dow8": "37.0",
    "Iats": "37.1",
    "CVsA": "37.2",
    "J7fI": "37.3",
    "FXXa": "37.4",
    "IkHh": "37.5",
    "z51d": "37.6",
    "1gvy": "37.7",
    "y4Dh": "37.8",
    "UmSq": "37.9",
    "CNp8": "38.0",
    "hGjv": "38.1",
    "9qQU": "38.2",
    "kkiU": "38.3",
    "fvEx": "38.4",
    "ShBU": "38.5",
    "6tPF": "38.6",
    "LOUv": "38.7",
    "Y44O": "38.8",
    "PrY3": "38.9",
    "aXBK": "39.0",
    "t6rg": "39.1",
    "TnGz": "39.2",
    "2LUd": "39.3",
    "UueN": "39.4",
    "ihFx": "39.5",
    "ha5l": "39.6",
    "SYNu": "39.7",
    "aPSw": "39.8",
    "5cQd": "39.9",
    "VaRw": "40.0",
    "lxgX": "40.1",
    "XB23": "40.2",
    "2o1o": "40.3",
    "iV1D": "40.4",
    "RO0s": "40.5",
    "Jy4c": "40.6",
    "zYrt": "40.7",
    "CDuD": "40.8",
    "vcvr": "40.9",
    "jKmh": "41.0",
    "MP4m": "41.1",
    "OFKM": "41.2",
    "8xHa": "41.3",
    "prRu": "41.4",
    "3sfg": "41.5",
    "WH4Y": "41.6",
    "T36G": "41.7",
    "dHTE": "41.8",
    "Zkwj": "41.9",
    "33WV": "42.0",
    "xsF3": "42.1",
    "oJDl": "42.2",
    "82TJ": "42.3",
    "KLVv": "42.4",
    "sKSW": "42.5",
    "xXyt": "42.6",
    "fneL": "42.7",
    "m3MY": "42.8",
    "GYqG": "42.9",
    "zoSh": "43.0",
    "Aoy8": "43.1",
    "7ni6": "43.2",
    "XlLz": "43.3",
    "ci3k": "43.4",
    "vJpZ": "43.5",
    "OZ5W": "43.6",
    "HEsy": "43.7",
    "tBne": "43.8",
    "DqAr": "43.9",
    "zwyu": "44.0",
    "ryIJ": "44.1",
    "cmQx": "44.2",
    "QsO4": "44.3",
    "Qyrn": "44.4",
    "s7VK": "44.5",
    "r9fv": "44.6",
    "l0tD": "44.7",
    "0Blr": "44.8",
    "fKlV": "44.9",
    "jqDu": "45.0",
    "OPwG": "45.1",
    "yUYE": "45.2",
    "YsKU": "45.3",
    "sbOS": "45.4",
    "bfcP": "45.5",
    "pt3q": "45.6",
    "Pszn": "45.7",
    "TcZ3": "45.8",
    "CAaB": "45.9",
    "DzZJ": "46.0",
    "Dh4l": "46.1",
    "Ym72": "46.2",
    "m3Bx": "46.3",
    "jMie": "46.4",
    "GnCT": "46.5",
    "GD7c": "46.6",
    "SAwi": "46.7",
    "DBHH": "46.8",
    "fjLw": "46.9",
    "EZIF": "47.0",
    "Ebo3": "47.1",
    "0DyY": "47.2",
    "gQai": "47.3",
    "p1SL": "47.4",
    "I1aO": "47.5",
    "2xk4": "47.6",
    "p8kr": "47.7",
    "A12I": "47.8",
    "KnvQ": "47.9",
    "Zhnu": "48.0",
    "OVUk": "48.1",
    "pDF4": "48.2",
    "KB9J": "48.3",
    "wlKD": "48.4",
    "x3ag": "48.5",
    "bpMy": "48.6",
    "m0qt": "48.7",
    "SrTt": "48.8",
    "k8kn": "48.9",
    "4dD9": "49.0",
    "5IKJ": "49.1",
    "ECkF": "49.2",
    "c9Ax": "49.3",
    "HUdH": "49.4",
    "jcSF": "49.5",
    "JiNl": "49.6",
    "vvE1": "49.7",
    "Ofh9": "49.8",
    "6twd": "49.9",
    "ExmG": "50.0",
    "Vr3F": "50.1",
    "hdqN": "50.2",
    "6M7N": "50.3",
    "6omi": "50.4",
    "sVF3": "50.5",
    "3WiY": "50.6",
    "XzCS": "50.7",
    "i6TU": "50.8",
    "2YwN": "50.9",
    "6fl8": "51.0",
    "qNDF": "51.1",
    "9Nv9": "51.2",
    "bIUG": "51.3",
    "5LGf": "51.4",
    "PgT7": "51.5",
    "3RD6": "51.6",
    "APAD": "51.7",
    "mNeb": "51.8",
    "MGyZ": "51.9",
    "zEoo": "52.0",
    "nGmm": "52.1",
    "Rz6s": "52.2",
    "x9O6": "52.3",
    "jZVX": "52.4",
    "ixSy": "52.5",
    "LdLP": "52.6",
    "sKqp": "52.7",
    "VWgx": "52.8",
    "KdLV": "52.9",
    "vj1x": "53.0",
    "89qA": "53.1",
    "XP96": "53.2",
    "RRtN": "53.3",
    "Fm2D": "53.4",
    "EWa1": "53.5",
    "wMbp": "53.6",
    "rOpL": "53.7",
    "E8VF": "53.8",
    "1NXH": "53.9",
    "OH2g": "54.0",
    "cNJd": "54.1",
    "iDFD": "54.2",
    "41Xq": "54.3",
    "JhvT": "54.4",
    "68g3": "54.5",
    "qxIv": "54.6",
    "EBVk": "54.7",
    "ZyoM": "54.8",
    "lYUO": "54.9",
    "yjtB": "55.0",
    "jsvk": "55.1",
    "xqkA": "55.2",
    "hTlL": "55.3",
    "twTG": "55.4",
    "Hv8w": "55.5",
    "AAM0": "55.6",
    "Qslx": "55.7",
    "j8vd": "55.8",
    "iK6I": "55.9",
    "BO8T": "56.0",
    "P78f": "56.1",
    "EjxW": "56.2",
    "8ha4": "56.3",
    "HiD0": "56.4",
    "9xHS": "56.5",
    "MA1W": "56.6",
    "bbOg": "56.7",
    "v1ve": "56.8",
    "yaKV": "56.9",
    "9FOd": "57.0",
    "bKdm": "57.1",
    "v7DJ": "57.2",
    "XoVX": "57.3",
    "ri4U": "57.4",
    "d1fU": "57.5",
    "fspo": "57.6",
    "MXOr": "57.7",
    "bDY2": "57.8",
    "xcBK": "57.9",
    "0t8K": "58.0",
    "BfmD": "58.1",
    "RsMa": "58.2",
    "tMth": "58.3",
    "ows2": "58.4",
    "ruxH": "58.5",
    "GZyV": "58.6",
    "NqD8": "58.7",
    "V5E2": "58.8",
    "BK2K": "58.9",
    "Cfaj": "59.0",
    "Fdvg": "59.1",
    "p1zJ": "59.2",
    "OeBk": "59.3",
    "3l9j": "59.4",
    "EsTd": "59.5",
    "AL44": "59.6",
    "8sHp": "59.7",
    "D2ZI": "59.8",
    "mO9t": "59.9",
    "2PUj": "60.0",
    "vewi": "60.1",
    "bLZ3": "60.2",
    "Vlf6": "60.3",
    "jNDQ": "60.4",
    "XrQS": "60.5",
    "QB6u": "60.6",
    "HEpu": "60.7",
    "p580": "60.8",
    "Y3Dm": "60.9",
    "mOdZ": "61.0",
    "HkyM": "61.1",
    "fMWW": "61.2",
    "wFh2": "61.3",
    "yXwg": "61.4",
    "vL71": "61.5",
    "OFD8": "61.6",
    "oQTc": "61.7",
    "SNXQ": "61.8",
    "lHXY": "61.9",
    "1URd": "62.0",
    "gvcb": "62.1",
    "Gn1A": "62.2",
    "BU64": "62.3",
    "OXi7": "62.4",
    "sXJm": "62.5",
    "7klV": "62.6",
    "aLMj": "62.7",
    "R0YI": "62.8",
    "zIBY": "62.9",
    "UYvl": "63.0",
    "35Z5": "63.1",
    "kyVm": "63.2",
    "WXQ2": "63.3",
    "2tW6": "63.4",
    "SnRA": "63.5",
    "HRUC": "63.6",
    "BKlP": "63.7",
    "XkOA": "63.8",
    "rGiY": "63.9",
    "BjmK": "64.0",
    "16sE": "64.1",
    "DQ5D": "64.2",
    "iFuw": "64.3",
    "DRzw": "64.4",
    "O9HC": "64.5",
    "yZ7M": "64.6",
    "Xu9L": "64.7",
    "BBlE": "64.8",
    "P1AT": "64.9",
    "Q2uL": "65.0",
    "5l44": "65.1",
    "rhwy": "65.2",
    "CF7S": "65.3",
    "qgB0": "65.4",
    "qeva": "65.5",
    "Gvj8": "65.6",
    "PA3V": "65.7",
    "ydlc": "65.8",
    "TGoK": "65.9",
    "FNrY": "66.0",
    "RHCI": "66.1",
    "iuU3": "66.2",
    "noGS": "66.3",
    "bMTi": "66.4",
    "sNAt": "66.5",
    "YqtF": "66.6",
    "2o6b": "66.7",
    "aC6e": "66.8",
    "nOBJ": "66.9",
    "AdG5": "67.0",
    "dUon": "67.1",
    "pubZ": "67.2",
    "iJ7q": "67.3",
    "rjYG": "67.4",
    "Myvf": "67.5",
    "3hK2": "67.6",
    "q2RZ": "67.7",
    "yOGg": "67.8",
    "618r": "67.9",
    "z9YN": "68.0",
    "9KEY": "68.1",
    "dejU": "68.2",
    "m0I4": "68.3",
    "pIOP": "68.4",
    "TDXH": "68.5",
    "Zy8t": "68.6",
    "dcEH": "68.7",
    "FYP6": "68.8",
    "cTTL": "68.9",
    "rLg0": "69.0",
    "afkE": "69.1",
    "6R1v": "69.2",
    "J7A3": "69.3",
    "XHnR": "69.4",
    "OVZ6": "69.5",
    "oWmG": "69.6",
    "ItLB": "69.7",
    "nvBw": "69.8",
    "rvM4": "69.9",
    "1Pj3": "70.0",
    "RXLC": "70.1",
    "MGGw": "70.2",
    "HoAJ": "70.3",
    "lqIs": "70.4",
    "pKxL": "70.5",
    "vdH6": "70.6",
    "llkA": "70.7",
    "hxmu": "70.8",
    "9w3F": "70.9",
    "kBJy": "71.0",
    "09Cg": "71.1",
    "MUiV": "71.2",
    "hK6g": "71.3",
    "55Vx": "71.4",
    "LYqb": "71.5",
    "bMmu": "71.6",
    "DE7m": "71.7",
    "PjFD": "71.8",
    "cNSN": "71.9",
    "jEiD": "72.0",
    "xzjL": "72.1",
    "QR6a": "72.2",
    "be56": "72.3",
    "OQ0p": "72.4",
    "iOfL": "72.5",
    "hrc0": "72.6",
    "BLXk": "72.7",
    "emES": "72.8",
    "V4xW": "72.9",
    "SKmy": "73.0",
    "Ttqn": "73.1",
    "vyGP": "73.2",
    "wfAC": "73.3",
    "CykJ": "73.4",
    "JpqV": "73.5",
    "RQIo": "73.6",
    "DZIT": "73.7",
    "r3fU": "73.8",
    "iA1S": "73.9",
    "3Zw8": "74.0",
    "U4wV": "74.1",
    "fbyJ": "74.2",
    "iZRI": "74.3",
    "he9G": "74.4",
    "BtMd": "74.5",
    "zqC6": "74.6",
    "XqKU": "74.7",
    "N1pd": "74.8",
    "8R09": "74.9",
    "1PbK": "75.0",
    "VRtM": "75.1",
    "GeG2": "75.2",
    "q9wl": "75.3",
    "t1uP": "75.4",
    "TBeZ": "75.5",
    "Rm6G": "75.6",
    "DTzO": "75.7",
    "eSaw": "75.8",
    "Ccr4": "75.9",
    "SbaF": "76.0",
    "loxy": "76.1",
    "9NTz": "76.2",
    "tSI2": "76.3",
    "SksZ": "76.4",
    "v1sr": "76.5",
    "MImN": "76.6",
    "0wAa": "76.7",
    "3BTA": "76.8",
    "7Dkc": "76.9",
    "PLqD": "77.0",
    "a5B8": "77.1",
    "BUQv": "77.2",
    "Mwjf": "77.3",
    "hulH": "77.4",
    "qZun": "77.5",
    "6o2E": "77.6",
    "usWa": "77.7",
    "IJtP": "77.8",
    "RlAf": "77.9",
    "BLqh": "78.0",
    "nW37": "78.1",
    "HhjI": "78.2",
    "5WUO": "78.3",
    "CgF8": "78.4",
    "5xNX": "78.5",
    "2sHY": "78.6",
    "b4GK": "78.7",
    "g8uE": "78.8",
    "LrgV": "78.9",
    "w6bH": "79.0",
    "8RJI": "79.1",
    "CY2t": "79.2",
    "1JsN": "79.3",
    "b7YH": "79.4",
    "zHIl": "79.5",
    "ezLk": "79.6",
    "JDNX": "79.7",
    "4qdc": "79.8",
    "uGAr": "79.9",
    "A0dC": "80.0",
    "1yTx": "80.1",
    "Co1b": "80.2",
    "P2C4": "80.3",
    "9b97": "80.4",
    "SaFq": "80.5",
    "RHNj": "80.6",
    "rOmL": "80.7",
    "I6Lm": "80.8",
    "IQk0": "80.9",
    "RHv0": "81.0",
    "jvhs": "81.1",
    "KnaS": "81.2",
    "qgCU": "81.3",
    "b9Q9": "81.4",
    "XnxH": "81.5",
    "WBJZ": "81.6",
    "3lkp": "81.7",
    "O5sP": "81.8",
    "QVsV": "81.9",
    "r29o": "82.0",
    "Moo5": "82.1",
    "4nSW": "82.2",
    "ko65": "82.3",
    "kqt5": "82.4",
    "ynA5": "82.5",
    "Nyhf": "82.6",
    "4qzO": "82.7",
    "31iS": "82.8",
    "CvJ3": "82.9",
    "2VhW": "83.0",
    "Fb4t": "83.1",
    "GxYG": "83.2",
    "6ueM": "83.3",
    "akCE": "83.4",
    "U1zK": "83.5",
    "8Oky": "83.6",
    "uvo9": "83.7",
    "EBcm": "83.8",
    "r3pp": "83.9",
    "dRd0": "84.0",
    "vQVh": "84.1",
    "r6l8": "84.2",
    "v8TP": "84.3",
    "YG9e": "84.4",
    "Lbqc": "84.5",
    "ooA8": "84.6",
    "wYF1": "84.7",
    "9Eu6": "84.8",
    "iMY4": "84.9",
    "o2rl": "85.0",
    "3kp6": "85.1",
    "oJzX": "85.2",
    "oKBD": "85.3",
    "DFnI": "85.4",
    "psq6": "85.5",
    "YlQS": "85.6",
    "bEj5": "85.7",
    "FMNC": "85.8",
    "LycE": "85.9",
    "8FDf": "86.0",
    "ntcl": "86.1",
    "rSbR": "86.2",
    "nkNa": "86.3",
    "DtFP": "86.4",
    "q1xl": "86.5",
    "1xSX": "86.6",
    "6LL4": "86.7",
    "fONU": "86.8",
    "z88H": "86.9",
    "toRv": "87.0",
    "SnZ2": "87.1",
    "O9al": "87.2",
    "FUWs": "87.3",
    "JUfO": "87.4",
    "v6PD": "87.5",
    "UCQD": "87.6",
    "iCkN": "87.7",
    "UFsR": "87.8",
    "EOAO": "87.9",
    "NdR4": "88.0",
    "qTWU": "88.1",
    "L1PW": "88.2",
    "cjVT": "88.3",
    "plVi": "88.4",
    "NXf2": "88.5",
    "uQWb": "88.6",
    "EtB9": "88.7",
    "biWA": "88.8",
    "ww9K": "88.9",
    "ie1d": "89.0",
    "VrR2": "89.1",
    "nCok": "89.2",
    "bnPK": "89.3",
    "guDU": "89.4",
    "1bWd": "89.5",
    "IDdm": "89.6",
    "rRmQ": "89.7",
    "5Z0y": "89.8",
    "s8aZ": "89.9",
    "q5WJ": "90.0",
    "0ZSd": "90.1",
    "gK2J": "90.2",
    "r27w": "90.3",
    "8WKe": "90.4",
    "IXkL": "90.5",
    "k8Nm": "90.6",
    "NsA9": "90.7",
    "E7WH": "90.8",
    "CtGw": "90.9",
    "DkbU": "91.0",
    "iDYN": "91.1",
    "m7ci": "91.2",
    "qI2a": "91.3",
    "JlHl": "91.4",
    "msOo": "91.5",
    "LVf7": "91.6",
    "Ytpl": "91.7",
    "CnqZ": "91.8",
    "vVre": "91.9",
    "OFZB": "92.0",
    "9BhT": "92.1",
    "Wfr1": "92.2",
    "Sznv": "92.3",
    "aoVI": "92.4",
    "5wQT": "92.5",
    "3D0P": "92.6",
    "ucyh": "92.7",
    "AZh9": "92.8",
    "y4X0": "92.9",
    "hbso": "93.0",
    "fpg2": "93.1",
    "UxhY": "93.2",
    "aFHF": "93.3",
    "lUUH": "93.4",
    "bk39": "93.5",
    "YX8d": "93.6",
    "IxRe": "93.7",
    "CZ4a": "93.8",
    "4XYN": "93.9",
    "IzoG": "94.0",
    "a6cm": "94.1",
    "dJvU": "94.2",
    "06zi": "94.3",
    "jsNj": "94.4",
    "XkTt": "94.5",
    "RMMR": "94.6",
    "fTlJ": "94.7",
    "OFAD": "94.8",
    "yR7h": "94.9",
    "M8vY": "95.0",
    "lHAv": "95.1",
    "oWmm": "95.2",
    "SJQR": "95.3",
    "CNGp": "95.4",
    "6oRP": "95.5",
    "kUGG": "95.6",
    "bllW": "95.7",
    "IfEd": "95.8",
    "gyhk": "95.9",
    "lTVd": "96.0",
    "nbYq": "96.1",
    "o0pG": "96.2",
    "z2kV": "96.3",
    "FYG5": "96.4",
    "Veyw": "96.5",
    "a2wN": "96.6",
    "9ENk": "96.7",
    "cXq1": "96.8",
    "aOc5": "96.9",
    "5Z06": "97.0",
    "cfpG": "97.1",
    "zKUy": "97.2",
    "ZSye": "97.3",
    "D8oU": "97.4",
    "Plpc": "97.5",
    "FF8K": "97.6",
    "NxI9": "97.7",
    "7ain": "97.8",
    "PLEk": "97.9",
    "MlyX": "98.0",
    "0MuR": "98.1",
    "LE2T": "98.2",
    "jWDm": "98.3",
    "33YF": "98.4",
    "VADT": "98.5",
    "bFJ4": "98.6",
    "vLFW": "98.7",
    "oIic": "98.8",
    "psNk": "98.9",
    "FLpr": "99.0",
    "9AI9": "99.1",
    "pTck": "99.2",
    "kYdc": "99.3",
    "LaIo": "99.4",
    "woeD": "99.5",
    "8hiS": "99.6",
    "rGjt": "99.7",
    "UlJg": "99.8",
    "s7cX": "99.9",
    "VSod": "100.0",
    "7Hdi": "100.1",
    "lVFF": "100.2",
    "Hwts": "100.3",
    "cKEk": "100.4",
    "jsEq": "100.5",
    "41sg": "100.6",
    "Cxbp": "100.7",
    "WuHH": "100.8",
    "0BbZ": "100.9",
    "L7Ol": "101.0",
    "2hGO": "101.1",
    "Zaeq": "101.2",
    "0DVX": "101.3",
    "S1yz": "101.4",
    "euSq": "101.5",
    "IbNH": "101.6",
    "Ekbt": "101.7",
    "UaHo": "101.8",
    "2FqC": "101.9",
    "civL": "102.0",
    "7b1H": "102.1",
    "QVum": "102.2",
    "5AgX": "102.3",
    "cljj": "102.4",
    "YwzG": "102.5",
    "HdJl": "102.6",
    "qvRc": "102.7",
    "nDHu": "102.8",
    "csMI": "102.9",
    "OBX4": "103.0",
    "O1K7": "103.1",
    "vzyP": "103.2",
    "DnVl": "103.3",
    "EE77": "103.4",
    "vq1A": "103.5",
    "hasP": "103.6",
    "v6FY": "103.7",
    "xNUD": "103.8",
    "RO55": "103.9",
    "TAfx": "104.0",
    "5QS1": "104.1",
    "fJxN": "104.2",
    "4XA8": "104.3",
    "62Ry": "104.4",
    "v1dO": "104.5",
    "02BH": "104.6",
    "evNP": "104.7",
    "IijQ": "104.8",
    "llzs": "104.9",
    "uLAR": "105.0",
    "RLev": "105.1",
    "rEDD": "105.2",
    "9RLp": "105.3",
    "On0U": "105.4",
    "tfKY": "105.5",
    "Q5qE": "105.6",
    "PfJt": "105.7",
    "uwhD": "105.8",
    "MzQ3": "105.9",
    "4d1v": "106.0",
    "PMMm": "106.1",
    "AVE2": "106.2",
    "Oxf9": "106.3",
    "R0VA": "106.4",
    "jAHy": "106.5",
    "FyLD": "106.6",
    "R0Xm": "106.7",
    "BjTI": "106.8",
    "pkpk": "106.9",
    "JsXe": "107.0",
    "mTK0": "107.1",
    "V8f6": "107.2",
    "AZVc": "107.3",
    "OVng": "107.4",
    "K3cO": "107.5",
    "fuRV": "107.6",
    "7acQ": "107.7",
    "GJnE": "107.8",
    "htvW": "107.9",
    "A2tN": "108.0",
    "kiEw": "108.1",
    "5uG5": "108.2",
    "Yny9": "108.3",
    "4P0Z": "108.4",
    "536C": "108.5",
    "2ZuH": "108.6",
    "G8NN": "108.7",
    "s1Ou": "108.8",
    "nEYS": "108.9",
    "pnL5": "109.0",
    "hNHR": "109.1",
    "VHvW": "109.2",
    "ocN9": "109.3",
    "2YB7": "109.4",
    "vm9Y": "109.5",
    "Royn": "109.6",
    "dPlK": "109.7",
    "Rv6E": "109.8",
    "qZot": "109.9",
    "ZQ51": "110.0",
    "ok9W": "110.1",
    "kbx1": "110.2",
    "knCH": "110.3",
    "eh7Y": "110.4",
    "REr1": "110.5",
    "acMW": "110.6",
    "iz0H": "110.7",
    "esxy": "110.8",
    "tQtu": "110.9",
    "oiU5": "111.0",
    "0er9": "111.1",
    "Gyny": "111.2",
    "dlCB": "111.3",
    "yoJc": "111.4",
    "kepy": "111.5",
    "wHeM": "111.6",
    "VoYE": "111.7",
    "IpMZ": "111.8",
    "Aps8": "111.9",
    "fScy": "112.0",
    "D7Ei": "112.1",
    "6kir": "112.2",
    "TbTO": "112.3",
    "ghoP": "112.4",
    "odoi": "112.5",
    "zVJe": "112.6",
    "H9ET": "112.7",
    "wL01": "112.8",
    "6HVA": "112.9",
    "YoH7": "113.0",
    "mXpz": "113.1",
    "Ii7x": "113.2",
    "35Zm": "113.3",
    "JJfC": "113.4",
    "T1AR": "113.5",
    "aDeI": "113.6",
    "piOr": "113.7",
    "6sDu": "113.8",
    "QxpV": "113.9",
    "aoS1": "114.0",
    "3BMN": "114.1",
    "Ybsb": "114.2",
    "GmDS": "114.3",
    "zf1a": "114.4",
    "MT7O": "114.5",
    "q6Ew": "114.6",
    "Xv2u": "114.7",
    "n90V": "114.8",
    "QvWQ": "114.9",
    "yjOV": "115.0",
    "mnxq": "115.1",
    "sru7": "115.2",
    "Zcnx": "115.3",
    "unJw": "115.4",
    "Z8U4": "115.5",
    "wUv6": "115.6",
    "HX9W": "115.7",
    "9pRD": "115.8",
    "oD56": "115.9",
    "4JFV": "116.0",
    "zK03": "116.1",
    "TyAQ": "116.2",
    "PKZQ": "116.3",
    "YH7n": "116.4",
    "lM09": "116.5",
    "vTT1": "116.6",
    "WnJr": "116.7",
    "c8jq": "116.8",
    "1Um2": "116.9",
    "dFJ5": "117.0",
    "MoII": "117.1",
    "5waK": "117.2",
    "uwCV": "117.3",
    "IH9I": "117.4",
    "bm6Q": "117.5",
    "un2X": "117.6",
    "qzjD": "117.7",
    "Wcmq": "117.8",
    "zNwS": "117.9",
    "3wAh": "118.0",
    "vvYM": "118.1",
    "AbAc": "118.2",
    "twhc": "118.3",
    "MrhF": "118.4",
    "M3B2": "118.5",
    "J2EK": "118.6",
    "EsX1": "118.7",
    "v8nt": "118.8",
    "8Gau": "118.9",
    "H3Uv": "119.0",
    "fTV0": "119.1",
    "jeuV": "119.2",
    "qP1W": "119.3",
    "OmGE": "119.4",
    "ZwOa": "119.5",
    "TxvK": "119.6",
    "hM0a": "119.7",
    "qEMW": "119.8",
    "HCtZ": "119.9",
    "Np7V": "120.0",
    "OSHi": "120.1",
    "hxgV": "120.2",
    "UfSq": "120.3",
    "7xCx": "120.4",
    "nSpU": "120.5",
    "GNR4": "120.6",
    "5ICp": "120.7",
    "cRDK": "120.8",
    "MVsy": "120.9"
}