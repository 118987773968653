import styled from "styled-components"
import { RegistrationForm } from "./template";
import { mentalHealth } from "../../constants/registration/mental-health";

const Fields = styled.div`
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    margin-top: 30px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-template-areas: 
        "mental_health"
        "social_life"
        "stress"
`;

const requiredFields = [
    'mental_health',
    'social_life',
    'stress'
]

export const RegistrationForm04 = () => {

    return <RegistrationForm
        subtitle='Mental Health'
        title='How have you been feeling lately?'
        Fields={Fields}
        fields={Object.values(mentalHealth)}
        progress={70}
        requiredFields={requiredFields}
        nextRoute='/registration/5'
    />
    
}