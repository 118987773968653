const yesNoOptions = {
    options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
        
    ],
    noDropdown: true,
    optionsGridStyle: {
        gridAutoFlow: 'column',
        gridTemplateRows: '1fr',
        gridTemplateColumns: 'repeat(2, min-content)'
    }
}


export let medicalHistory = {
    has_cancer: {
        label: 'Have you been diagnosed with cancer?',
        id: 'has_cancer',
        ...yesNoOptions
    },
    has_diabetes: {
        label: 'Have you been diagnosed with diabetes?',
        id: 'has_diabetes',
        ...yesNoOptions
    },
    medicine: {
        label: 'Are you currently taking prescription medications?',
        id: 'medicine',
        ...yesNoOptions,
        nestedQuestion: {
            condition: 'Yes',
            label: 'What medications are you taking?',
            id: 'medicine_consumption',
            disableTrim: true
        },
    },
    supplements: {
        label: 'Do you take supplements?',
        id: 'supplements',
        ...yesNoOptions,
        nestedQuestion: {
            condition: 'Yes',
            label: 'What supplements are you taking?',
            id: 'supplement_consumption',
            disableTrim: true
        },
    },

}